import React, { useState, useEffect, Suspense } from 'react';
import { useRecoilState } from 'recoil';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
// import { Table } from 'react-bootstrap';
import { makeStyles, Divider, Container, CssBaseline, Grid, Typography } from '@material-ui/core';
import { Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import {LoaderButton} from '../../components';
import { useAppContext } from '../../libs/contextLib';
import { inventoryState } from '../../recoil/atoms';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	topRow: {
		direction: 'row',
		justify: 'flex-start',
		alignItems: 'center',
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	table: {
		minWidth: 500,
	},
}));

export default function Inventory(props) {

	Inventory.propTypes = {
		count: PropTypes.number.isRequired,
		onChangePage: PropTypes.func.isRequired,
		page: PropTypes.number.isRequired,
		rowsPerPage: PropTypes.number.isRequired,
	};
	const classes = useStyles();
	const history = useHistory();
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);
	// const [inventory, setInventory] = useState([]);
	const [inventory, setInventory] = useRecoilState(inventoryState);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const emptyRows = rowsPerPage - Math.min(rowsPerPage, inventory.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		async function onLoad() {
			if (!isAuthenticated) {
				return;
			}
			if (Object.keys(inventory).length === 0) {
				try {
					setInventory(JSON.parse(localStorage.getItem('inventoryState')).value);
				} catch {
					const data = await loadData();
					setInventory(data);
				}
			}
			setIsLoading(false);
		}
  
		onLoad();
	}, [isAuthenticated]);

	function loadData() {
		return API.get('acc', 'acc/list-by-type/inventory');
	}
	function goToNew(){
		history.push('/inventory/new');
	}
	
	return (
		<div>
			<Suspense fallback={<div>Loading...</div>}>
				<Container>
					<CssBaseline />
					<Grid container spacing={2} className={classes.topRow}>
						<Grid item xs={4}>
							<Typography variant="h5">
					Inventory
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<LoaderButton
								onClick={goToNew}
							>Add New
							</LoaderButton>
						</Grid>
					</Grid>
					<Divider className={classes.divider} />
					<TableContainer component={Paper}>
						<Table className={classes.table} size="medium">
							<TableHead><TableRow><TableCell>Inventory</TableCell></TableRow></TableHead>
							<TableHead>
								<TableRow>
									<TableCell>Allergen</TableCell>
									<TableCell>Type</TableCell>
									<TableCell>Lot</TableCell>
									<TableCell>Expiration</TableCell>
									<TableCell>Edit</TableCell>
									<TableCell>Delete</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{(rowsPerPage > 0
									? inventory.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									: inventory
								).map((i, index) => (
									<TableRow key={index}>
										<TableCell>{i.allergenName}</TableCell>
										<TableCell>{i.allergenType}</TableCell>
										<TableCell>{i.lot}</TableCell>
										<TableCell>{i.stockExpiration}</TableCell>
										<TableCell>Edit</TableCell>
										<TableCell>Delete</TableCell>
									</TableRow>
								))}

								{emptyRows > 0 && (
									<TableRow style={{ height: 53 * emptyRows }}>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
							<TableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
										colSpan={3}
										count={inventory.length}
										rowsPerPage={rowsPerPage}
										page={page}
										SelectProps={{
											inputProps: { 'aria-label': 'rows per page' },
											native: true,
										}}
										onChangePage={handleChangePage}
										onChangeRowsPerPage={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</TableRow>
							</TableFooter>
						</Table>
					</TableContainer>
				</Container>
				
			</Suspense>
			
		</div>
	);
}

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};