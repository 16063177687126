
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
	body: {
		padding: 10
	},
	table: { 
		display: 'table', 
		width: 'auto', 
		borderStyle: 'solid', 
		borderColor: '#bfbfbf',
		borderWidth: 1, 
		borderRightWidth: 0, 
		borderBottomWidth: 0 
	}, 
	tableRow: { 
		margin: 'auto', 
		flexDirection: 'row' 
	}, 
	tableColHeader: { 
		width: '25%', 
		borderStyle: 'solid', 
		borderColor: '#bfbfbf',
		borderBottomColor: '#000',
		borderWidth: 1, 
		borderLeftWidth: 0, 
		borderTopWidth: 0
	},   
	tableCol: { 
		width: '25%', 
		borderStyle: 'solid', 
		borderColor: '#bfbfbf',
		borderWidth: 1, 
		borderLeftWidth: 0, 
		borderTopWidth: 0 
	}, 
	tableCellHeader: {
		margin: 'auto', 
		fontSize: 12,
		fontWeight: 500
	},  
	tableCell: { 
		margin: 'auto', 
		fontSize: 10 
	},
	logo: {
		height: 40,
		width: 200,
	}
});




export default function BottleContents(props) {
	BottleContents.propTypes = {
		activePatientDemographics: PropTypes.object.isRequired,
		selectedBottles: PropTypes.array.isRequired,
	};
    
	const { activePatientDemographics, selectedBottles } = props;

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.logo}>
					<Image src="/images/acc_logo.png" />
				</View>
				<View>
					<Text>
                        Bottle Contents - {activePatientDemographics.firstName} {activePatientDemographics.lastName}
					</Text>
				</View>
				<View>
					<Text>
                        Dob: {activePatientDemographics.dob} 
					</Text>
				</View>
				<View>
					<Text> 
                        MRN: {activePatientDemographics.mrn}</Text>
				</View>
				<View>
					<Text>
						{'\n\n'}
					</Text>
				</View>
				{selectedBottles.map((bottle, index) => (
					<View key={bottle.itemId}>
						<View><Text> 
							Bottle: {bottle.name}{bottle.strength} ({bottle.itemId})
						</Text></View>
						<View key={index}>
                            
							<View style={styles.table}> 
								<View style={styles.tableRow}> 
									<View style={styles.tableColHeader}> 
										<Text style={styles.tableCellHeader}>Name</Text> 
									</View> 
									<View style={styles.tableColHeader}> 
										<Text style={styles.tableCellHeader}>Volume</Text> 
									</View> 
									<View style={styles.tableColHeader}> 
										<Text style={styles.tableCellHeader}>Manufacturer</Text> 
									</View> 
									<View style={styles.tableColHeader}> 
										<Text style={styles.tableCellHeader}>Lot</Text> 
									</View> 
									<View style={styles.tableColHeader}> 
										<Text style={styles.tableCellHeader}>Concentration</Text> 
									</View>
									<View style={styles.tableColHeader}> 
										<Text style={styles.tableCellHeader}>Expiration</Text> 
									</View> 
								</View>
								{bottle.contents.map((content, index) => (
									<View style={styles.tableRow} key={index}> 
										<View style={styles.tableCol}> 
											<Text style={styles.tableCell}>{content.inventory.name} ({content.inventory.allergenType})</Text> 
										</View> 
										<View style={styles.tableCol}> 
											<Text style={styles.tableCell}>{content.volume}</Text> 
										</View> 
										<View style={styles.tableCol}>
											<Text style={styles.tableCell}>{content.inventory.manufacturer}</Text> 
										</View>
										<View style={styles.tableCol}>
											<Text style={styles.tableCell}>{content.inventory.lot}</Text> 
										</View>
										<View style={styles.tableCol}>
											<Text style={styles.tableCell}>{content.inventory.stockConcentration} ({content.inventory.stockUnits})</Text> 
										</View>
										<View style={styles.tableCol}> 
											<Text style={styles.tableCell}>{moment(content.inventory.stockExpiration).format('MM/DD/YY')}</Text> 
										</View> 
									</View> 
								))}
								{(bottle.diluentVolume > 0) ?
									<View><Text> 
                                    Diluent: {bottle.diluentVolume} (remove: {bottle.diluentVolume - 0.5})
									</Text></View>
									:
									<View><Text> 
                                    Diluent: N/A
									</Text></View>
								}
							</View>
							<Text>
								{'\n\n'}
							</Text>
						</View>
					</View>
				))}

			</Page>
		</Document>
	);
}

