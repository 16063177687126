import React, { Fragment, Suspense, useState, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles, Divider, Button, TextField, Container, Grid, Typography} from '@material-ui/core';
import { Autocomplete, ToggleButtonGroup, ToggleButton} from '@material-ui/lab';
import { API } from 'aws-amplify';
import { useAppContext } from '../libs/contextLib';
import { onError } from '../libs/errorLib';
import {LoaderButton, Queue} from '../components';
import { locationState, patientListState } from '../recoil/atoms';
import { filteredPatientListState } from '../recoil/selectors';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	selectRow: {
		direction: 'row',
		justify: 'flex-start',
		alignItems: 'center',
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
}));

export default function Dashboard() {
	const classes = useStyles();
	const history = useHistory();
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);
	const offices = [
		{ name: 'Norwalk', value: 'norwalk' },
		{ name: 'Shelton', value: 'shelton' },
	];
	const [selectedPatient, setSelectedPatient] = useState(null);
	const [location, setLocation] = useRecoilState(locationState);
	const [patientList, setPatientList] = useRecoilState(patientListState);

	const PatientSelectField = () => {	
		return (
			<Autocomplete
				id="combo-box-demo"
				options={patientList}
				getOptionLabel={option =>`${option.firstName} ${option.lastName} (${option.mrn})`}
				onChange={(event, newValue) => {
					setSelectedPatient(newValue);
				}}
				fullWidth
				renderInput={(params) => <TextField {...params} label="Choose a patient ..." variant="outlined" />}
			/>
		);
	};

	const QueueContainer = () => {
		const fileteredPatientList = useRecoilValue(filteredPatientListState);	
		return (
			<div>
				<Queue queueList={fileteredPatientList} isLoading={isLoading} rowSelected={patientSelected}/>
			</div>
		);
	};

	const SelectedPatientComponent = () => {
		return (
			<Typography variant="h5">
				{`Selected Patient: ${selectedPatient.firstName} ${selectedPatient.lastName} (${selectedPatient.mrn})`}
			</Typography>
		);
	};

	const NoSelectedPatientComponent = () => {
		return (
			<Typography variant="h5">
				No selected patient
			</Typography>
		);
	};

		
	useEffect(() => {
		async function onLoad() {
			if (!isAuthenticated) {
				return;
			}
			try {
				const data = await API.get('acc', 'acc/list-by-type/demographics');
				setPatientList(data);
			} catch (e) {
				onError(e);
				console.log('Error', e);
			}
			setIsLoading(false);
		}
		onLoad();
	}, [isAuthenticated]);

	function patientSelected(selected){
		setSelectedPatient(selected);
	}

	function goToPatient(){
		history.push(`/home/${selectedPatient.mrn}`);
	}

	const createNewPatient = () => {
		history.push('/patient/new/');
	};

	const toggleQueue = (event, value) => {
		if (value === 'norwalk') {
			setLocation(value);
		} else if (value === 'shelton') {
			setLocation(value);
		}
	};

	function updateQueue(action) {
		// Temporary: add to queue of selected toggle - should look at patient.location
		// Update server
		let data = {
			itemId: selectedPatient.itemId,
			location: (action === 'add') ? location : 'none'
			// location: (action === 'add') ? selectedPatient.location : 'none'
		};
		API.put('acc', 'acc/update-demographics', {
			body: data
		});
		// Update local
		let queue = '';
		if (action === 'add') {
			queue = location;
			// queue = selectedPatient.location;
		} else {
			queue = 'none';
		}
		const elementsIndex = patientList.findIndex(element => element.itemId === selectedPatient.itemId );
		let newArray = [...patientList];
		newArray[elementsIndex] = {...newArray[elementsIndex], queue: queue};
		setPatientList(newArray);
	}

	function renderLander() {
		return (
			<div className="lander">
				<h1>Allergy Shot App</h1>
				<p>A simple allergy tracking app</p>
				<div>
					<Link to="/login" className="btn btn-info btn-lg">
            Login
					</Link>
					<Link to="/signup" className="btn btn-success btn-lg">
            Signup
					</Link>
				</div>
			</div>
		);
	}

	function renderDashboard() {
		return (
			<Fragment>
				<Suspense fallback={<div>Loading...</div>}>
					<Container>
						<Divider className={classes.divider} />
						<Grid container spacing={1} className={classes.selectRow}>
							<Grid item xs={6}>
								<PatientSelectField />
							</Grid>
							<Grid item xs={4}>
								{(selectedPatient) ? <SelectedPatientComponent /> : <NoSelectedPatientComponent />}
							</Grid>
							<Grid item xs={2}>
								<LoaderButton
									onClick={goToPatient}
									disabled={!selectedPatient}
								>Go To Patient</LoaderButton>
								<LoaderButton onClick={!isLoading ? createNewPatient : null}>Create New Patient</LoaderButton>
							</Grid>
						</Grid>
						<Divider className={classes.divider} />
						<Grid container spacing={2} className={classes.selectRow}>
							<Grid item xs={6}>
								<Typography variant="h5">Queue</Typography>
							</Grid>
							<Grid item xs={6}>
								<ToggleButtonGroup
									value={location}
									exclusive
									onChange={toggleQueue}
								>
									{offices.map((office, index) => (
										<ToggleButton value={office.value} aria-label="left aligned" key={index}>
											{office.name}
										</ToggleButton>
									))}
								</ToggleButtonGroup>
								<Button variant="outlined" onClick={() => updateQueue('add')} disabled={!selectedPatient}>Add to Queue</Button>
								<Button variant="outlined" onClick={() => updateQueue('remove')} disabled={!selectedPatient}>Remove from Queue</Button>
							</Grid>
						</Grid>
						<div>
							<QueueContainer />
						</div>
					</Container>
				</Suspense>
			</Fragment>
		);
	}

	return (
		<div className="Dashboard">
			{isAuthenticated ? renderDashboard() : renderLander()}
		</div>
	);
}