import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useAppContext } from '../../libs/contextLib';
import ChargesTable from './ChargeTable';
import moment from 'moment';
import { chargesState } from '../../recoil/atoms';
import {LoaderButton} from '../../components';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	textField: {
		backgroundColor: 'WhiteSmoke'
	},
	button: {
		padding: 10,
		margin: 10
	},
}));

export default function Charges() {
	const history = useHistory();
	const classes = useStyles();
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	const [startDate, setStartDate] = useState(moment().startOf('day').format('yyyy-MM-DD').toString());
	const [endDate, setEndDate] = useState(moment().endOf('day').format('yyyy-MM-DD').toString());
	
	// Load from recoilState
	const [charges, setCharges] = useRecoilState(chargesState);

	useEffect(() => {
		async function onLoad() {
			setIsLoading(true);
			if (!isAuthenticated) {
				return;
			}
			let today = moment();
			const dates = {
				itemType: 'charge',
				startDate: today.startOf('day').toString(),
				endDate: today.endOf('day').toString(),
			};
			let tempCharges = await loadData(dates);
			setCharges(tempCharges);
			setIsLoading(false);
		}
  
		onLoad();
	}, [isAuthenticated]);
        
	function loadData(dates) {
		return API.post('acc', 'acc/list-by-createdAt', {body: dates });
	}
	
	// also change field values
	async function loadToday(){
		let today = moment();
		try {
			const dates = {
				itemType: 'charge',
				startDate: today.startOf('day').toString(),
				endDate: today.endOf('day').toString(),
			};
			const data = await loadData(dates);
			setCharges(data);
		} catch(e) {
			console.log('ERROR', e);
		}
	}
    
	async function loadYesterday(){
		let yesterday = moment().add(-1, 'days');
		try {
			const dates = {
				itemType: 'charge',
				startDate: yesterday.startOf('day').toString(),
				endDate: yesterday.endOf('day').toString(),
			};
			const data = await loadData(dates);
			setCharges(data);
		} catch(e) {
			console.log('ERROR', e);
		}
	}
    
	async function handleDateRange(event){
		event.preventDefault();
		console.log('DATES', startDate, endDate);
		try {
			const dates = {
				itemType: 'charge',
				startDate: `${startDate}T00:00`,
				endDate: `${endDate}T23:59`,
			};
			const data = await loadData(dates);
			setCharges(data);
		} catch(e) {
			console.log('ERROR', e);
		}
	}

	function submitUpdateCharges(){
		return API.put('acc', 'acc/update-charges', { body: charges });
	}

	function goBack(){
		history.goBack();
	}

	function toggleCharge(itemId){
		console.log('toggle\', ', itemId);
		let newArray = [...charges];
		let elementsIndex = newArray.findIndex(i => i.itemId === itemId);
		newArray[elementsIndex] = {...newArray[elementsIndex], charged: !newArray[elementsIndex].charged};
		setCharges(newArray);
	}

	function selectAll(){
		const newArray = charges.map(e => ({...e, charged: true}));
		setCharges(newArray);
	}

	function unSelectAll(){
		const newArray = charges.map(e => ({...e, charged: false}));
		setCharges(newArray);
	}

	if (isLoading) {
		return (
			<div>Loading ...</div>
		);
	} else {
		return (
			<Container>
				<Grid container>
					<Grid item xs={12}>
						<Typography component="h1" variant="h5">
						Charges
						</Typography>
					</Grid>
	
					<form className={classes.form} noValidate onSubmit={handleDateRange}>
						<Grid container spacing={1}>
							<Grid item xs={3}>
								<TextField
									id="startDate"
									label="Start Date"
									type="date"
									fullWidth
									value={startDate}
									onChange={e => setStartDate(e.target.value)}
									// defaultValue="2017-05-24"
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									id="endDate"
									label="End Date"
									type="date"
									fullWidth
									value={endDate}
									onChange={e => setEndDate(e.target.value)}
									className={classes.textField}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>							
							<Grid item xs={2}>
								<LoaderButton type="submit">Refresh</LoaderButton>
							</Grid>
							<Grid item xs={2}>
								<LoaderButton onClick={loadToday}>Today</LoaderButton>
							</Grid>
							<Grid item xs={2}>
								<LoaderButton onClick={loadYesterday}>Yesterday</LoaderButton>
							</Grid>

						</Grid>
					</form>
				</Grid>
				<ChargesTable charges={charges} toggleCharge={toggleCharge}/>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography component="h1" variant="h5">
						Charges
						</Typography>
					</Grid>
					<Grid item xs={4}>
						<LoaderButton
							className={classes.button}
							fullWidth
							onClick={selectAll}
						>Select All</LoaderButton>
					</Grid>
					<Grid item xs={4}>
						<LoaderButton
							className={classes.button}
							fullWidth
							onClick={unSelectAll}
						>Un-Select All</LoaderButton>
					</Grid>
					<Grid item xs={4}>
						<LoaderButton
							className={classes.button}
							fullWidth
							onClick={submitUpdateCharges}
						>Submit</LoaderButton>
					</Grid>
				</Grid>
				<LoaderButton
					className={classes.button}
					fullWidth
					onClick={goBack}
				>Back</LoaderButton>
	
			</Container>
			
		);
	}
}