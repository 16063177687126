import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {LoaderButton} from '../../components';
import { useAppContext } from '../../libs/contextLib';
import { useFormFields } from '../../libs/hooksLib';
import { onError } from '../../libs/errorLib';
// import FacebookButton from '../../components/FacebookButton';
import './Login.css';

export default function Login() {
	const { userHasAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	const [fields, handleFieldChange] = useFormFields({
		email: '',
		password: ''
	});

	function validateForm() {
		return fields.email.length > 0 && fields.password.length > 0;
	}

	async function handleSubmit(event) {
		event.preventDefault();

		setIsLoading(true);

		try {
			await Auth.signIn(fields.email, fields.password);
			userHasAuthenticated(true);
		} catch (e) {
			onError(e);
			setIsLoading(false);
		}
	}

	// function handleFbLogin() {
	// 	userHasAuthenticated(true);
	// }

	return (
		<div className="Login">
			<form onSubmit={handleSubmit}>
				{/* <FacebookButton
					onLogin={handleFbLogin}
				/> */}
				<hr />
				<FormGroup controlId="email" bsSize="large">
					<FormLabel>Email</FormLabel>
					<FormControl
						autoFocus
						type="email"
						value={fields.email}
						onChange={handleFieldChange}
					/>
				</FormGroup>
				<FormGroup controlId="password" bsSize="large">
					<FormLabel>Password</FormLabel>
					<FormControl
						type="password"
						value={fields.password}
						onChange={handleFieldChange}
					/>
				</FormGroup>
				<Link to="/login/reset">Forgot password?</Link>
				<LoaderButton
					block
					type="submit"
					bsSize="large"
					isLoading={isLoading}
					disabled={!validateForm()}
				>
          Login
				</LoaderButton>
			</form>
		</div>
	);
}