import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: 'red',
		// color: theme.palette.text.secondary,
	},
}));

export const PatientBanner = props => {
	const { patient } = props;
	const classes = useStyles();
	return (

		<Paper className={classes.paper}>
			<Typography variant="h4">
				Name: {patient.firstName} {patient.lastName} - 
				Dob: {patient.dob} - 
				MRN: {patient.mrn}	
			</Typography>
		</Paper>
	);
};

PatientBanner.propType = {
	patient: PropTypes.object
};

PatientBanner.defaultProps = {
	patient: {},
};