import React, { useEffect, useState, Suspense } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Form, Formik, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import FolderIcon from '@material-ui/icons/Folder';
import {LoaderButton, PatientBanner} from '../../components';
import { onError } from '../../libs/errorLib';
import { useAppContext } from '../../libs/contextLib';
import { activePatientDemographicsState, activePatientBottlesState, inventoryState } from '../../recoil/atoms';
import './NewBottles.css';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	textField: {
		padding: 10,
	},
	button: {
		padding: 10,
	}
}));

export default function NewBottles() {
	const classes = useStyles();
	let { patientId } = useParams();
	const history = useHistory();
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	const [activePatientDemographics, setActivePatientDemographics] = useRecoilState(activePatientDemographicsState);
	const [activePatientBottles, setActivePatientBottles] = useRecoilState(activePatientBottlesState);
	const [inventory, setInventory] = useRecoilState(inventoryState);

	useEffect(() => {
		// If local state is empty, load from localstorage
		if (Object.keys(activePatientDemographics).length === 0) {
			setActivePatientDemographics(JSON.parse(localStorage.getItem('activePatientDemographicsState')).value);
			setActivePatientBottles(JSON.parse(localStorage.getItem('activePatientBottlesState')).value);
		}
		async function onLoad() {
			if (!isAuthenticated) {
				return;
			}
			if (Object.keys(inventory).length === 0) {
				
				try {
					setInventory(JSON.parse(localStorage.getItem('inventoryState')).value);
					let test = JSON.parse(localStorage.getItem('inventoryState').value);
					console.log("HI", test);
				} catch {
					const data = await loadInventory();
					console.log("DATA", data);
					setInventory(data);
				}
			}
			console.log("INVENTORY", inventory);
			setIsLoading(false);
		}
		onLoad();
	}, [isAuthenticated]);
    

	function loadInventory() {
		return API.get('acc', 'acc/list-by-type/inventory');
	}

	function goBack(){
		history.goBack();
	}

	function getNextName(){
		try {
			let bottleNameArray = [...new Set(activePatientBottles.map(a => a.name))];
			var highestLetter = bottleNameArray.sort().pop();
			return String.fromCharCode(((highestLetter.charCodeAt(0) + 1 - 65) % 25) + 65);
		} catch {
			return 'A';
		}
	}

	// async function submitNewPatient(event) {
	async function submitNewBottles(values, isValid) {
		setIsLoading(true);
		const { bottleName, maxStrength, minStrength, contents } = values;
		const updatedContentsArray = contents.map(obj=> ({
			...obj,
			volume: parseFloat(obj.volume)
		}));
		const bottles = { patientId,
			patient: activePatientDemographics,
			name: bottleName,
			maxStrength: parseInt(maxStrength),
			minStrength: parseInt(minStrength),
			contents: updatedContentsArray
		};
		try {
			const newBottles = await createBottles(bottles);
			// add to state
			let newArray = activePatientBottles.concat(newBottles.body);
			setActivePatientBottles(newArray);
			goBack();
		} catch (e) {
			onError(e);
			setIsLoading(false);
		}
	}
  
  
	function createBottles(bottles) {
		return API.post('acc', 'acc/create-bottles', {body: bottles });
	}

	// Schema for yup
	const validationSchema = Yup.object().shape({
		bottleName: Yup.string()
			.length(1, 'Names must have be exactly 1 character')
			.required('Name is required'),
		maxStrength: Yup.number()
			.min(1, 'Strengths must have be at least 1')
			.max(7, 'Strength must be less than 7')
			.required('Strength is required'),
		minStrength: Yup.number()
			.min(1, 'Strengths must have be at least 1')
			.max(7, 'Strength must be less than 7')
			.required('Strength is required'),
		contents: Yup.array()
			.of(
				Yup.object().shape({
					inventory: Yup.object().required('req'),
					volume: Yup.number().required('req'),
				})
			)
			.required('required-field'),
		
	});

	
	return (
		<Container>
			<Suspense className={classes.paper} fallback={<div>Loading...</div>}>
				<PatientBanner patient={activePatientDemographics} />
				<Typography component="h1" variant="h5">
					New Bottle - Need patient banner
				</Typography>
				<Formik
					enableReinitialize={true}
					initialValues={{ bottleName: getNextName(), maxStrength: '1', minStrength: '5', contents: [{}] }}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						submitNewBottles(values);
					}}
				>
					{({
						errors,
						touched,
						values,
						handleChange,
						handleBlur,
						setFieldValue,
						setFieldTouched,
						isValid,
						/* and other goodies */
					}) => (
						<Grid container spacing={2}>
							<Form className={classes.form}>
								<Grid item xs={12}>
									<TextField
										className={classes.textField}
										error={errors.bottleName && touched.bottleName}
										name="bottleName"
										variant="outlined"
										fullWidth
										value={values.bottleName}
										onChange={handleChange}
										onBlur={handleBlur}
										id="bottleName"
										label="Bottle Name"
										autoFocus
										helperText={
											errors.bottleName && touched.bottleName
												? errors.bottleName
												: null
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className={classes.textField}
										error={errors.maxStrength && touched.maxStrength}
										name="maxStrength"
										variant="outlined"
										type="number"
										fullWidth
										value={values.maxStrength}
										onChange={handleChange}
										onBlur={handleBlur}
										id="maxStrength"
										label="Strongest Strength"
										helperText={
											errors.maxStrength && touched.maxStrength
												? errors.maxStrength
												: null
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										className={classes.textField}
										error={errors.minStrength && touched.minStrength}
										name="minStrength"
										variant="outlined"
										type="number"
										fullWidth
										value={values.minStrength}
										onChange={handleChange}
										onBlur={handleBlur}
										id="minStrength"
										label="Weakest Strength"
										helperText={
											errors.minStrength && touched.minStrength
												? errors.minStrength
												: null
										}
									/>
								</Grid>

								
								<Grid item xs={2}>
									<Badge color="secondary" badgeContent={values.contents.length}>
										<FolderIcon />
									</Badge>
								</Grid>
								<FieldArray
									name="contents"
									render={arrayHelpers => (
										<div>
											{values.contents && values.contents.length > 0 ? (
												values.contents.map((content, index) => (
													<div key={index}>
														<Grid container justify="flex-start" direction='row' spacing={0}>
															<Grid item xs={4}>
																<TextField
																	className={classes.textField}
																	id={`contents[${index}].inventory`}
																	name={`contents[${index}].inventory`}
																	select
																	fullWidth
																	variant="outlined"
																	onChange={e => {
																		handleChange(e);
																		setFieldValue(`contents[${index}].volume`, e.target.value.defaultVolume);
																		// setTimeout(() => setFieldTouched(`contents[${index}].volume`, true));
																		// setFieldTouched(`contents[${index}].volume`, true);
																		// console.log('E', e.target.value);
																	}}
																	// onBlur={e => {
																	// 	handleBlur(e);
																	// 	// setFieldValue(`contents[${index}].inventory`, e.target.value);
																	// 	// setFieldTouched(`contents[${index}].inventory`, true);
																	// 	// setValues(fields: { [field: string]: any }, shouldValidate?: boolean) 
																	// 	setFieldValue(`contents[${index}].volume`, '0.66');
																	// 	setTimeout(() => setFieldTouched(`contents[${index}].volume`, true));
																	// 	// values.contents[index].volume ='hi';
																	// 	// ? e.target.value * values.price
																	// 	// : 0;
																	// 	setFieldTouched(`contents[${index}].volume`, true);
																	// 	console.log('E', e.target.value);
																	// }}
																	onBlur={handleBlur}
																	helperText="Please select your contents"
																>
																	{inventory.map((option) => (
																		<MenuItem key={option.itemId} value={option}>
																			{option.allergenName} ({option.allergenType})
																		</MenuItem>
																	))}
																</TextField>
															</Grid>
															<Grid item xs={2}>
																<TextField
																	className={classes.textField}
																	error={errors.volume && touched.volume}
																	// autoComplete="fname"
																	id={`contents[${index}].volume`}
																	name={`contents[${index}].volume`}
																	value={values.contents[index].volume}
																	variant="outlined"
																	type="text"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	label="Volume"
																	helperText={
																		errors.volume && touched.volume
																			? errors.volume
																			: null
																	}
																/>
															</Grid>
															<Grid item xs={2}>
																<IconButton onClick={() => arrayHelpers.remove(index)} color="primary">
																	<DeleteIcon color="primary" style={{ fontSize: 20 }}/>
																</IconButton>
																<IconButton onClick={() => arrayHelpers.push({ inventory: '', volume: '' })} color="primary">
																	<AddIcon color="primary" style={{ fontSize: 20 }}/>
																</IconButton>
															</Grid>
															<Grid item xs={2}></Grid>
														</Grid>
													</div>
												))
											) : (
												<LoaderButton
													onClick={() => arrayHelpers.push('')}
													className={classes.button}
													fullWidth
												>Add Contents</LoaderButton>
											)}
										</div>
									)}
								/>
								

								<LoaderButton
									className={classes.button}
									fullWidth
									type="submit"
									isLoading={isLoading}
									disabled={!isValid}
								>Create</LoaderButton>
								<LoaderButton
									className={classes.button}
									fullWidth
									type="submit"
									onClick={goBack}
								>Back</LoaderButton>
							</Form>
						</Grid>
					)}
				</Formik>	
			</Suspense>
		</Container>
	);
}