// import React, { useState, useEffect, Suspense } from 'react';
// import { useRecoilState } from 'recoil';
// import { Link, useParams, useHistory } from 'react-router-dom';
// import { Button, ListGroupItem, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
// import { LinkContainer } from 'react-router-bootstrap';
// import Select from 'react-select';
// import { Formik, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import {LoaderButton, PatientBanner, ScitTable} from '../../components';
// import { API } from 'aws-amplify';
// import { useAppContext } from '../../libs/contextLib';
// import { onError } from '../../libs/errorLib';
// import { activePatientDemographicsState, 
// 	activePatientBottlesState,
// 	activePatientNextScitState,
// 	activePatientScitState,
// } from '../../recoil/atoms';
// import { filteredPatientListState } from '../../recoil/selectors';
// import moment from 'moment';
// import './Home.css';
// import {generateXmlExample} from "./xml-example";


export default function Bottle() {
	// const history = useHistory();
	// const [activePatientDemographics, setActivePatientDemographics] = useRecoilState(activePatientDemographicsState);
	// const [activePatientBottles, setActivePatientBottles] = useRecoilState(activePatientBottlesState);


	// const [activeBottle, setActiveBottle] = useState(null);

	// const { itemId } = useParams();
	// const [isLoading, setIsLoading] = useState(false);
	// const [isDeleting, setIsDeleting] = useState(false);

	// useEffect(() => {
	// 	// If local state is empty, load from localstorage
	// 	if (Object.keys(activePatientDemographics).length === 0) {
	// 		setActivePatientDemographics(JSON.parse(localStorage.getItem('activePatientDemographicsState')).value);
	// 		setActivePatientBottles(JSON.parse(localStorage.getItem('activePatientBottlesState')).value);
	// 		setActiveBottle(JSON.parse(localStorage.getItem('activePatientBottlesState')).value.filter((bottle) => bottle.itemId === itemId)[0]);
	// 	} else {
	// 		setActiveBottle(activePatientBottles.filter((bottle) => bottle.itemId === itemId)[0]);
	// 	}
	// 	console.log('active', activePatientBottles.filter((bottle) => bottle.itemId === itemId)[0]);
	// }, []);


	// function goBack(){
	// 	history.goBack();
	// }

	// function updateScit() {
	// 	// // api
	// 	// API.put('patients', `/patients/update/${activeScit.patientId}/${activeScit.itemId}`, {
	// 	// 	body: activeScit
	// 	// });
	// 	// // local
	// 	// const elementsIndex = activePatientScit.findIndex(element => element.itemId === activeScit.itemId );
	// 	// let newArray = [...activePatientScit];
	// 	// newArray[elementsIndex] = {...newArray[elementsIndex],
	// 	// 	bottle: activeScit.bottle,
	// 	// 	volume: activeScit.volume
	// 	// };
	// 	// setActivePatientScit(newArray);
	// 	// goBack();
	// }


	// function deleteScit(dose) {
	// 	// // api
	// 	// API.del('patients', `/patients/delete/${activeScit.patientId}/${activeScit.itemId}`);
	// 	// // local
	// 	// const elementsIndex = activePatientScit.findIndex(element => element.itemId === dose.itemId );
	// 	// let newArray = [...activePatientScit];
	// 	// newArray.splice(elementsIndex, 1);
	// 	// setActivePatientScit(newArray);
	// }

  
	// return (
	// 	<div>
	// 		<div>
	// 			<h2>Edit Bottle</h2>
	// 			<Suspense fallback={<div>Loading...</div>}>
	// 				<PatientBanner patient={activePatientDemographics} />
	// 			</Suspense>
	// 		</div>
	// 		<div className="SCIT">
	// 			{activeBottle && (
	// 				<div>
	// 					<div>Name: {activeBottle.name}</div>
	// 					<div>Strength: {activeBottle.strength}</div>
	// 					<div>Mixed: {moment(activeBottle.createdAt).format('MM/DD/YY hh:mm')}</div>
	// 					<div>Expires: {moment(activeBottle.expiration).format('MM/DD/YY hh:mm')}</div>
	// 					<div>Contents:</div>
	// 					{activeBottle.contents.map((c, index) => {
	// 						return (
	// 							<div key={index}>
	// 								<div>{c.inventory.allergenName} ({c.inventory.allergenType}) - {c.volume} ml</div>
	// 							</div>
	// 						);
	// 					})}
	// 				</div>
	// 			)}
	// 		</div>
	// 		<LoaderButton
	// 			fullWidth
	// 			type="submit"
	// 			isLoading={isLoading}
	// 			onClick={updateScit}
	// 		>Update</LoaderButton>
	// 		<LoaderButton
	// 			fullWidth
	// 			type="submit"
	// 			isLoading={isLoading}
	// 			onClick={deleteScit}
	// 		>Delete</LoaderButton>
	// 		<LoaderButton
	// 			fullWidth
	// 			type="submit"
	// 			isLoading={isLoading}
	// 			onClick={goBack}
	// 		>Back</LoaderButton>
	// 	</div>
	// );
}