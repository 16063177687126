import React from 'react';
// import { Button } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { MdRefresh } from 'react-icons/md';
import './LoaderButton.css';

export function LoaderButton({
	isLoading,
	className = '',
	disabled = false,
	...props
}) {
	return (
		<Button
			variant="contained"
			color="primary"
			className={`LoaderButton ${className}`}
			disabled={disabled || isLoading}
			{...props}
		>
			{isLoading && <MdRefresh className="spinning" />}
			{props.children}
		</Button>
	);
}