import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@material-ui/core';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';

const note = {
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
};

const useStyles2 = makeStyles({
	table: {
		minWidth: 500,
	},
});

export function ScitTable({
	isLoading,
	scitData,
	deleteScit,
	editScit,
	...props
}) {

	const classes = useStyles2();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, scitData.length - page * rowsPerPage);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	if (isLoading) {
		return (
			<CircularProgress />
		);
	}
	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} size="medium">
				<TableHead><TableRow><TableCell>Previous Injections</TableCell></TableRow></TableHead>
				<TableHead>
					<TableRow>
						<TableCell>Date</TableCell>
						<TableCell>Bottle</TableCell>
						<TableCell>Volume</TableCell>
						<TableCell>Note</TableCell>
						<TableCell></TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(rowsPerPage > 0
						? scitData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
						: scitData
					).map((dose, index) => (
						<TableRow key={index}>
							<TableCell>{moment(dose.createdAt).format('MM/DD/YY hh:mm')}</TableCell>
							<TableCell style={{ backgroundColor: dose.bottle.color }}>{dose.bottle.name}{dose.bottle.strength}</TableCell>
							<TableCell>{dose.volume}</TableCell>
							<TableCell style={note}>{dose.note}</TableCell>
							<TableCell onClick={() => editScit(dose)}>Edit</TableCell>
							<TableCell onClick={() => deleteScit(dose)}>
								<IconButton aria-label="delete">
									<DeleteIcon />
								</IconButton>
							</TableCell>
						</TableRow>
					))}

					{emptyRows > 0 && (
						<TableRow style={{ height: 53 * emptyRows }}>
							<TableCell colSpan={6} />
						</TableRow>
					)}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
							colSpan={3}
							count={scitData.length}
							rowsPerPage={rowsPerPage}
							page={page}
							SelectProps={{
								inputProps: { 'aria-label': 'rows per page' },
								native: true,
							}}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
}

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};



// export default function CustomPaginationActionsTable() {


//   return (
//     <TableContainer component={Paper}>
//       <Table className={classes.table} aria-label="custom pagination table">
// <TableBody>
//   {(rowsPerPage > 0
//     ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//     : rows
//   ).map((row) => (
//     <TableRow key={row.name}>
//       <TableCell component="th" scope="row">
//         {row.name}
//       </TableCell>
//       <TableCell style={{ width: 160 }} align="right">
//         {row.calories}
//       </TableCell>
//       <TableCell style={{ width: 160 }} align="right">
//         {row.fat}
//       </TableCell>
//     </TableRow>
//   ))}

//   {emptyRows > 0 && (
//     <TableRow style={{ height: 53 * emptyRows }}>
//       <TableCell colSpan={6} />
//     </TableRow>
//   )}
// </TableBody>
// <TableFooter>
//   <TableRow>
//     <TablePagination
//       rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
//       colSpan={3}
//       count={rows.length}
//       rowsPerPage={rowsPerPage}
//       page={page}
//       SelectProps={{
//         inputProps: { 'aria-label': 'rows per page' },
//         native: true,
//       }}
//       onChangePage={handleChangePage}
//       onChangeRowsPerPage={handleChangeRowsPerPage}
//       ActionsComponent={TablePaginationActions}
//     />
//   </TableRow>
// </TableFooter>
//       </Table>
//     </TableContainer>
//   );
// }
