import React, { useState, useEffect, Suspense } from 'react';
import { useRecoilState } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Divider, Container, Grid, Typography } from '@material-ui/core';
import {LoaderButton, PatientBanner, ScitTable} from '../../components';
import { API } from 'aws-amplify';
import { useAppContext } from '../../libs/contextLib';
import { onError } from '../../libs/errorLib';
import { PDFDownloadLink } from '@react-pdf/renderer';
import ScitHistory from './ScitHistory';
import { activePatientDemographicsState, 
	activePatientBottlesState,
	activePatientNextScitState,
	activePatientScitState,
} from '../../recoil/atoms';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	topRow: {
		direction: 'row',
		justify: 'flex-start',
		alignItems: 'center',
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
}));

export default function Home() {
	const classes = useStyles();
	let { mrn } = useParams();
	const history = useHistory();
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	// Load from recoilState
	const [activePatientDemographics, setActivePatientDemographics] = useRecoilState(activePatientDemographicsState);
	const [activePatientBottles, setActivePatientBottles] = useRecoilState(activePatientBottlesState);
	const [activePatientScit, setActivePatientScit] = useRecoilState(activePatientScitState);
	const [activePatientNextScit, setActivePatientNextScit] = useRecoilState(activePatientNextScitState);

	useEffect(() => {
		async function onLoad() {
			setIsLoading(true);
			if (!isAuthenticated) {
				return;
			}
			// Load from API
			if (Object.keys(activePatientDemographics).length === 0){
				try {
					const data = await loadData();
					console.log('data', data);
					setActivePatientDemographics(data.demographics);
					setActivePatientBottles(data.bottleData);
					setActivePatientScit(data.scitData);
					setActivePatientNextScit(data.nextScit);
				} catch (e) {
					onError(e);
				}
			}
			setIsLoading(false);
		}
  
		onLoad();
	}, [isAuthenticated]);

	function loadData() {
		return API.get('acc', `acc/prepare-scit/${mrn}`);
	}

	const goToBottles = () => {
		history.push(`/home/${mrn}/bottles/`);
	};
  
	const goToNewBottle = () => {
		history.push(`/home/${mrn}/bottles/new/`);
	};

	const goToNewScit = () => {
		history.push(`/home/${mrn}/scit/new/`);
	};

	function editScit(dose){
		history.push(`/home/${dose.mrn}/${dose.itemId}/edit`);
	}

	function deleteScit(dose) {
		// api
		API.del('acc', `acc/delete-item/scit/${dose.itemId}`);
		// local
		const elementsIndex = activePatientScit.findIndex(element => element.itemId === dose.itemId );
		let newArray = [...activePatientScit];
		newArray.splice(elementsIndex, 1);
		setActivePatientScit(newArray);
	}

	function renderScit() {
		return (
			<div className="notes">
				<ScitTable
					scitData={activePatientScit}
					isLoading={isLoading}
					deleteScit={deleteScit}
					editScit={editScit}
				/>
			</div>
		);
	}
	function renderNoScit() {
		return (
			<div>
				<h2>NO previous Doses</h2>
			</div>
		);
	}

	return (
		<div>
			<Suspense fallback={<div>Loading...</div>}>
				<Container>
					<Grid container spacing={2} className={classes.topRow}>
						<Grid item xs={4}>
							<Typography variant="h3">
					Patient Home
							</Typography>
						</Grid>
						<Grid item xs={8}>
							<PatientBanner patient={activePatientDemographics}/>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<LoaderButton>Change Demographics</LoaderButton>
						</Grid>
						<Grid item xs={4}>
							<LoaderButton onClick={goToBottles}>Manage Bottles</LoaderButton>
							<LoaderButton onClick={!isLoading ? goToNewBottle : null}>New Bottle</LoaderButton>
						</Grid>
						<Grid item xs={4}>
							<LoaderButton onClick={!isLoading ? goToNewScit : null}>New Shot</LoaderButton>
							<PDFDownloadLink document={
								<ScitHistory
									activePatientDemographics={activePatientDemographics}
									activePatientScit={activePatientScit}
								/>} fileName={`${activePatientDemographics.lastName}.pdf`
							}>
								{({ blob, url, loading, error }) => (loading ? 'Loading document...' : <LoaderButton>Shot History</LoaderButton>)}
							</PDFDownloadLink>
						</Grid>
					</Grid>
					<Divider className={classes.divider} />
					<Grid container spacing={2}>
						<Grid item xs={12}>
							{(activePatientScit.length > 0) ? renderScit() : renderNoScit()}
						</Grid>
					</Grid>
				</Container>
			</Suspense>
		</div>
	);
}