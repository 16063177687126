import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import {LoaderButton, PatientBanner} from '../../components';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useAppContext } from '../../libs/contextLib';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { PDFDownloadLink } from '@react-pdf/renderer';
import BottleContents from './BottleContents';
import moment from 'moment';
// import {useDymoCheckService, useDymoFetchPrinters, useDymoOpenLabel} from 'react-dymo-hooks';

import { activePatientDemographicsState, 
	activePatientBottlesState,
} from '../../recoil/atoms';
import { MdSwitchVideo } from 'react-icons/md';

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{ id: 'name', numeric: false, disablePadding: true, disableSort: false, label: 'Name' },
	{ id: 'strength', numeric: true, disablePadding: false, disableSort: false, label: 'Strength' },
	{ id: 'itemId', numeric: false, disablePadding: false, disableSort: false, label: 'Id' },
	{ id: 'createdAt', numeric: false, disablePadding: false, disableSort: false, label: 'Mix Date' },
	{ id: 'contents', numeric: false, disablePadding: false, disableSort: true, label: 'Contents' },
	{ id: 'expiration', numeric: false, disablePadding: false, disableSort: false ,label: 'Expiration Date' },
	{ id: 'view', numeric: false, disablePadding: false, disableSort: true, label: 'View' },
];

function EnhancedTableHead(props) {
    
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
    
	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						// checked={true}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all bottles' }}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					!headCell.disableSort ?
						<TableCell
							key={headCell.id}
							padding={headCell.disablePadding ? 'none' : 'default'}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : 'asc'}
								onClick={createSortHandler(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<span className={classes.visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</span>
								) : null}
							</TableSortLabel>
						</TableCell>
						:
						<TableCell key={headCell.id}>
							{headCell.label}
						</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
	root: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(1),
	},
	highlight:
    theme.palette.type === 'light'
    	? {
    		color: theme.palette.secondary.main,
    		backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    	}
    	: {
    		color: theme.palette.text.primary,
    		backgroundColor: theme.palette.secondary.dark,
    	},
	title: {
		flex: '1 1 100%',
	},
}));

// const EnhancedTableToolbar = (props) => {
// 	const classes = useToolbarStyles();
// 	const { numSelected } = props;

// 	return (
// 		<Toolbar
// 			className={clsx(classes.root, {
// 				[classes.highlight]: numSelected > 0,
// 			})}
// 		>
// 			{numSelected > 0 ? (
// 				<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
// 					{numSelected} selected
// 				</Typography>
// 			) : (
// 				<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
//           Bottles
// 				</Typography>
// 			)}

// 			{numSelected > 0 ? (
// 				<Tooltip title="Delete">
// 					<IconButton aria-label="delete">
// 						<DeleteIcon />
// 					</IconButton>
// 				</Tooltip>
// 			) : (
// 				<Tooltip title="Filter list">
// 					<IconButton aria-label="filter list">
// 						<FilterListIcon />
// 					</IconButton>
// 				</Tooltip>
// 			)}
// 		</Toolbar>
// 	);
// };

// EnhancedTableToolbar.propTypes = {
// 	numSelected: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	button: {
		padding: 10,
		margin: 5,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
}));

export default function EnhancedTable() {
	// const statusDymoService = useDymoCheckService();
	// const {statusFetchPrinters, printers} = useDymoFetchPrinters(statusDymoService);

	const history = useHistory();
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(false);
	// Load from recoilState
	const [activePatientDemographics, setActivePatientDemographics] = useRecoilState(activePatientDemographicsState);
	const [activePatientBottles, setActivePatientBottles] = useRecoilState(activePatientBottlesState);
    
	const classes = useStyles();
	const [order, setOrder] = React.useState('asc');
	const [orderBy, setOrderBy] = React.useState('calories');
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'http://labelwriter.com/software/dls/sdk/js/DYMO.Label.Framework.latest.js';
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		};
		
	}, []);

	useEffect(() => {
		// if (statusDymoService === 'error') {
		// 	console.log('error');
		// } else if (statusDymoService === 'loading') {
		// 	console.log('loading');
		// } else if (statusDymoService === 'success') {
		// 	console.log('success');
		// } else {
		// 	console.log('wawa', statusDymoService, statusFetchPrinters);
		// }

		// console.log('sFP', statusFetchPrinters);
		
		async function onLoad() {
			setIsLoading(true);
			if (!isAuthenticated) {
				return;
			}
			// If local state is empty, load from localstorage
			if (Object.keys(activePatientDemographics).length === 0) {
				setActivePatientDemographics(JSON.parse(localStorage.getItem('activePatientDemographicsState')).value);
				setActivePatientBottles(JSON.parse(localStorage.getItem('activePatientBottlesState')).value);
			}
			setIsLoading(false);
		}
		onLoad();
	}, [isAuthenticated]);
    
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			// const newSelecteds = activePatientBottles.map((i) => i.itemId);
			setSelected(activePatientBottles);
			return;
		}
		setSelected([]);
	};

	function printLabel(bottle)
	{
		try
		{
			var labelXml2 = `
			<?xml version="1.0" encoding="utf-8"?>
<DieCutLabel Version="8.0" Units="twips" MediaType="Default">
  <PaperOrientation>Landscape</PaperOrientation>
  <Id>Small30336</Id>
  <PaperName>30336 1 in x 2-1/8 in</PaperName>
  <DrawCommands>
    <RoundRectangle X="0" Y="0" Width="1440" Height="3060" Rx="180" Ry="180"/>
  </DrawCommands>
  <ObjectInfo>
    <TextObject>
      <Name>ACC</Name>
      <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
      <BackColor Alpha="0" Red="255" Green="255" Blue="255"/>
      <LinkedObjectName></LinkedObjectName>
      <Rotation>Rotation0</Rotation>
      <IsMirrored>False</IsMirrored>
      <IsVariable>False</IsVariable>
      <HorizontalAlignment>Left</HorizontalAlignment>
      <VerticalAlignment>Middle</VerticalAlignment>
      <TextFitMode>None</TextFitMode>
      <UseFullFontHeight>True</UseFullFontHeight>
      <Verticalized>False</Verticalized>
      <StyledText>
        <Element>
          <String>ALLERGY CENTER OF CONNECTICUT
</String>
          <Attributes>
            <Font Family="Helvetica" Size="7" Bold="False" Italic="False" Underline="True" Strikeout="False"/>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>
          </Attributes>
        </Element>
      </StyledText>
    </TextObject>
    <Bounds X="129.5999" Y="62.32989" Width="2656.143" Height="423.9884"/>
  </ObjectInfo>
</DieCutLabel>`;
			// open label
			var labelXml = '<?xml version="1.0" encoding="utf-8"?>\
			<DieCutLabel Version="8.0" Units="twips">\
			<PaperOrientation>Landscape</PaperOrientation>\
			<Id>Small30336</Id>\
			<PaperName>30336 1 in x 2-1/8 in</PaperName>\
			<DrawCommands>\
				<RoundRectangle X="0" Y="0" Width="1440" Height="3060" Rx="180" Ry="180"/>\
			</DrawCommands>\
			<ObjectInfo>\
				<TextObject>\
				<Name>ALLERGEN_VACCINE</Name>\
				<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
				<BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
				<LinkedObjectName></LinkedObjectName>\
				<Rotation>Rotation0</Rotation>\
				<IsMirrored>False</IsMirrored>\
				<IsVariable>False</IsVariable>\
				<HorizontalAlignment>Left</HorizontalAlignment>\
				<VerticalAlignment>Top</VerticalAlignment>\
				<TextFitMode>None</TextFitMode>\
				<UseFullFontHeight>True</UseFullFontHeight>\
				<Verticalized>False</Verticalized>\
				<StyledText>\
					<Element>\
					<String>Allergen vaccine</String>\
					<Attributes>\
						<Font Family="Lucida Grande" Size="8" Bold="False" Italic="False" Underline="True" Strikeout="False"/>\
						<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
					</Attributes>\
					</Element>\
				</StyledText>\
				</TextObject>\
				<Bounds X="100.0000" Y="50.0000" Width="1500.0000" Height="400.0000"/>\
			</ObjectInfo>\
				<ObjectInfo>\
				<TextObject>\
				<Name>CONTENTS</Name>\
				<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
				<BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
				<LinkedObjectName></LinkedObjectName>\
				<Rotation>Rotation0</Rotation>\
				<IsMirrored>False</IsMirrored>\
				<IsVariable>False</IsVariable>\
				<HorizontalAlignment>Left</HorizontalAlignment>\
				<VerticalAlignment>Top</VerticalAlignment>\
				<TextFitMode>None</TextFitMode>\
				<UseFullFontHeight>True</UseFullFontHeight>\
				<Verticalized>False</Verticalized>\
				<StyledText>\
					<Element>\
					<String>Contents</String>\
					<Attributes>\
						<Font Family="Lucida Grande" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
						<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
					</Attributes>\
					</Element>\
				</StyledText>\
				</TextObject>\
				<Bounds X="100.0000" Y="850.0000" Width="3000.0000" Height="400.0000"/>\
			</ObjectInfo>\
				<ObjectInfo>\
				<TextObject>\
				<Name>EXP</Name>\
				<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
				<BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
				<LinkedObjectName></LinkedObjectName>\
				<Rotation>Rotation0</Rotation>\
				<IsMirrored>False</IsMirrored>\
				<IsVariable>False</IsVariable>\
				<HorizontalAlignment>Left</HorizontalAlignment>\
				<VerticalAlignment>Top</VerticalAlignment>\
				<TextFitMode>None</TextFitMode>\
				<UseFullFontHeight>True</UseFullFontHeight>\
				<Verticalized>False</Verticalized>\
				<StyledText>\
					<Element>\
					<String>Expiration</String>\
					<Attributes>\
						<Font Family="Lucida Grande" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
						<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
					</Attributes>\
					</Element>\
				</StyledText>\
				</TextObject>\
				<Bounds X="100.0000" Y="1050.0000" Width="3000.0000" Height="400.0000"/>\
			</ObjectInfo>\
				<ObjectInfo>\
				<TextObject>\
				<Name>CONCENTRATION</Name>\
				<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
				<BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
				<LinkedObjectName></LinkedObjectName>\
				<Rotation>Rotation0</Rotation>\
				<IsMirrored>False</IsMirrored>\
				<IsVariable>False</IsVariable>\
				<HorizontalAlignment>Left</HorizontalAlignment>\
				<VerticalAlignment>Top</VerticalAlignment>\
				<TextFitMode>None</TextFitMode>\
				<UseFullFontHeight>True</UseFullFontHeight>\
				<Verticalized>False</Verticalized>\
				<StyledText>\
					<Element>\
						<String>concentration</String>\
					<Attributes>\
						<Font Family="Lucida Grande" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
						<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
					</Attributes>\
					</Element>\
				</StyledText>\
				</TextObject>\
				<Bounds X="100.0000" Y="650.0000" Width="3000.0000" Height="400.0000"/>\
			</ObjectInfo>\
			<ObjectInfo>\
				<TextObject>\
				<Name>PATIENT_NAME</Name>\
				<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
				<BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
				<LinkedObjectName></LinkedObjectName>\
				<Rotation>Rotation0</Rotation>\
				<IsMirrored>False</IsMirrored>\
				<IsVariable>False</IsVariable>\
				<HorizontalAlignment>Left</HorizontalAlignment>\
				<VerticalAlignment>Top</VerticalAlignment>\
				<TextFitMode>None</TextFitMode>\
				<UseFullFontHeight>True</UseFullFontHeight>\
				<Verticalized>False</Verticalized>\
				<StyledText>\
					<Element>\
					<String>Name</String>\
					<Attributes>\
						<Font Family="Lucida Grande" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
						<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
					</Attributes>\
					</Element>\
				</StyledText>\
				</TextObject>\
				<Bounds X="100.0000" Y="250.0000" Width="3000.0000" Height="400.0000"/>\
			</ObjectInfo>\
			<ObjectInfo>\
				<TextObject>\
				<Name>VIAL_NAME</Name>\
				<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
				<BackColor Alpha="0" Red="255" Green="255" Blue="255"/>\
				<LinkedObjectName></LinkedObjectName>\
				<Rotation>Rotation0</Rotation>\
				<IsMirrored>False</IsMirrored>\
				<IsVariable>False</IsVariable>\
				<HorizontalAlignment>Left</HorizontalAlignment>\
				<VerticalAlignment>Top</VerticalAlignment>\
				<TextFitMode>None</TextFitMode>\
				<UseFullFontHeight>True</UseFullFontHeight>\
				<Verticalized>False</Verticalized>\
				<StyledText>\
					<Element>\
					<String>Vial_name</String>\
					<Attributes>\
						<Font Family="Lucida Grande" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False"/>\
						<ForeColor Alpha="255" Red="0" Green="0" Blue="0"/>\
					</Attributes>\
					</Element>\
				</StyledText>\
				</TextObject>\
				<Bounds X="100.0000" Y="450.0000" Width="3000.0000" Height="200.0000"/>\
			</ObjectInfo>\
			</DieCutLabel>';

			var label = window.dymo.label.framework.openLabelXml(labelXml2);

			let contents = bottle.contents.map(a => a.abbreviation).join(', ');
			let exp = moment(bottle.expiration).format('MM/DD/YY');
			let concentration = getConcetration(bottle.strength);
			let patient_name = `${activePatientDemographics.firstName} ${activePatientDemographics.lastName}`;
			let vial_name = `${bottle.name}${bottle.strength}`;

			// set label text

			// label.setObjectText('contents', contents);
			// label.setObjectText('expiration', exp);
			// label.setObjectText('concentration', concentration);
			// label.setObjectText('createdAt', patient_name);
			// label.setObjectText('bottleName', vial_name);

			// label.setObjectText('CONTENTS', contents);
			// label.setObjectText('EXP', exp);
			// label.setObjectText('CONCENTRATION', concentration);
			// label.setObjectText('PATIENT_NAME', patient_name);
			// label.setObjectText('VIAL_NAME', vial_name);
			// select printer to print on
			// for simplicity sake just use the first LabelWriter printer
			var printers = window.dymo.label.framework.getPrinters();
			if (printers.length == 0)
				throw 'No DYMO printers are installed. Install DYMO printers.';

			var printerName = '';
			for (var i = 0; i < printers.length; ++i)
			{
				var printer = printers[i];
				if (printer.printerType == 'LabelWriterPrinter')
				{
					printerName = printer.name;
					break;
				}
			}

			if (printerName == '')
				throw 'No LabelWriter printers found. Install LabelWriter printer';
			//  set print parameters
			var printParams = {};
			printParams.twinTurboRoll = window.dymo.label.framework.TwinTurboRoll.Right;
			label.print(printerName, window.dymo.label.framework.createLabelWriterPrintParamsXml(printParams));

			// finally print the label
			//                label.print(printerName);
		}
		catch(e)
		{
			console.log(e.message || e);
		}
	}

	function getConcetration(strength){
		switch(strength) {
		case 1:
			return '1:1';
		case 2:
			return '1:10';
		case 3:
			return '1:100';
		case 4:
			return '1:1,000';
		case 5:
			return '1:10,000';
		default:
			return 'N/A';
		}
	}

	const handleClick = (event, row) => {
		// const selectedIndex = selected.indexOf(row.itemId);
		// pos = myArray.map(function(e) { return e.hello; }).indexOf('stevie');
		const selectedIndex = selected.findIndex(i => i.itemId === row.itemId);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, row);
			console.log('New', newSelected);
			console.log('Selected', selected);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// const isSelected = (itemId) => selected.indexOf(itemId) !== -1;
	const isSelected = (itemId) => selected.findIndex(i => i.itemId === itemId) !== -1;

	const emptyRows = rowsPerPage - Math.min(rowsPerPage, activePatientBottles.length - page * rowsPerPage);

	const goToNewBottle = () => {
		history.push(`/home/${activePatientDemographics.patientId}/bottles/new/`);
	};

	const goToBottle = (row) => {
		// console.log('BOTTLE', e);
		history.push(`/home/${row.ownerId}/bottle/${row.itemId}`);
	};

	function goBack(){
		history.goBack();
	}

	// function printLabels(){
	// 	console.log('selected', selected);
	// }
	function printLabels(){
		selected.forEach(bottle => {
			// console.log('for', bottle);
			printLabel(bottle);
		});
	}
	

    
	return (
		<Container>
			<PatientBanner patient={activePatientDemographics} />
			<Grid container>
				<Paper className={classes.paper}>
					<Grid item xs={12}>
						<TableContainer>
							<Table
								className={classes.table}
								aria-labelledby="tableTitle"
								size='medium'
								aria-label="enhanced table"
							>
								<EnhancedTableHead
									classes={classes}
									numSelected={selected.length}
									order={order}
									orderBy={orderBy}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={activePatientBottles.length}
								/>
								<TableBody>
									{stableSort(activePatientBottles, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											const isItemSelected = isSelected(row.itemId);
											const labelId = `enhanced-table-checkbox-${index}`;

											return (
												<TableRow
													hover
													onClick={(event) => handleClick(event, row)}
													role="checkbox"
													aria-checked={isItemSelected}
													tabIndex={-1}
													key={row.itemId}
													selected={isItemSelected}
												>
													<TableCell padding="checkbox">
														<Checkbox
															checked={isItemSelected}
															inputProps={{ 'aria-labelledby': labelId }}
														/>
													</TableCell>
													<TableCell component="th" id={labelId} scope="row" padding="none">
														{row.name}
													</TableCell>
													<TableCell>{row.strength}</TableCell>
													<TableCell>{row.itemId}</TableCell>
													<TableCell>{moment(row.createdAt).format('MM/DD/YY hh:mm')}</TableCell>
													<TableCell>{row.contents.map(a => a.abbreviation).join(', ')}</TableCell>
													<TableCell>{moment(row.expiration).format('MM/DD/YY hh:mm')}</TableCell>
													<TableCell onClick={() => goToBottle(row)}>View</TableCell>
													{/* <TableCell align="right">{row.protein}</TableCell> */}
												</TableRow>
											);
										})}
									{emptyRows > 0 && (
										<TableRow style={{ height: 53 * emptyRows }}>
											<TableCell colSpan={6} />
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							component="div"
							count={activePatientBottles.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</Grid>
				</Paper>
			
									
				
					
				{(selected.length > 0 )?
					<Grid container>
						<Grid item xs={12} sm={6}>
							<LoaderButton
								className={classes.button}
								fullWidth
								isLoading={isLoading}
								onClick={printLabels}
							>Print label</LoaderButton>
						</Grid>
						<Grid item xs={12} sm={6}>
							<PDFDownloadLink disabled={true} document={
								<BottleContents
									activePatientDemographics={activePatientDemographics}
									selectedBottles={selected}
								/>} fileName={`${activePatientDemographics.lastName}.pdf`
							}>
								{({ blob, url, loading, error }) => (loading ? 'Loading document...' :
									<LoaderButton
										className={classes.button}
										fullWidth
									>Print Contents</LoaderButton>)}
							</PDFDownloadLink>
						</Grid>
					</Grid>
					:
					null
				}
				<Grid item xs={12}>
					<LoaderButton
						fullWidth
						className={classes.button}
						onClick={!isLoading ? goToNewBottle : null}
					>New Bottle</LoaderButton>
				</Grid>
				<Grid item xs={12}>
					<LoaderButton
						className={classes.button}
						fullWidth
						onClick={goBack}
					>Back</LoaderButton>
				</Grid>
			</Grid>
		</Container>
	);
}
