
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
	body: {
		padding: 10
	},
	table: { 
		display: 'table', 
		width: 'auto', 
		borderStyle: 'solid', 
		borderColor: '#bfbfbf',
		borderWidth: 1, 
		borderRightWidth: 0, 
		borderBottomWidth: 0 
	}, 
	tableRow: { 
		margin: 'auto', 
		flexDirection: 'row' 
	}, 
	tableColHeader: { 
		width: '25%', 
		borderStyle: 'solid', 
		borderColor: '#bfbfbf',
		borderBottomColor: '#000',
		borderWidth: 1, 
		borderLeftWidth: 0, 
		borderTopWidth: 0
	},   
	tableCol: { 
		width: '25%', 
		borderStyle: 'solid', 
		borderColor: '#bfbfbf',
		borderWidth: 1, 
		borderLeftWidth: 0, 
		borderTopWidth: 0 
	}, 
	tableCellHeader: {
		margin: 'auto', 
		fontSize: 12,
		fontWeight: 500
	},  
	tableCell: { 
		margin: 'auto', 
		fontSize: 10 
	},
	logo: {
		height: 40,
		width: 200,
	}
});




export default function ScitHistory(props) {
	ScitHistory.propTypes = {
		activePatientDemographics: PropTypes.object.isRequired,
		activePatientScit: PropTypes.array.isRequired,
	};
    
	const { activePatientDemographics, activePatientScit } = props;

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.logo}>
					<Image src="/images/acc_logo.png" />
				</View>
				<View>
					<Text>
                        Shot History - {activePatientDemographics.firstName} {activePatientDemographics.lastName}
					</Text>
				</View>
				<View>
					<Text>
                        Dob: {activePatientDemographics.dob} 
					</Text>
				</View>
				<View>
					<Text> 
                        MRN: {activePatientDemographics.mrn}</Text>
				</View>
				<View>
					<Text></Text>
				</View>
				<View style={styles.table}> 
					<View style={styles.tableRow}> 
						<View style={styles.tableColHeader}> 
							<Text style={styles.tableCellHeader}>Date</Text> 
						</View> 
						<View style={styles.tableColHeader}> 
							<Text style={styles.tableCellHeader}>Bottle</Text> 
						</View> 
						<View style={styles.tableColHeader}> 
							<Text style={styles.tableCellHeader}>Volume</Text> 
						</View> 
						<View style={styles.tableColHeader}> 
							<Text style={styles.tableCellHeader}>Note</Text> 
						</View> 
					</View>
					{activePatientScit.map((dose, index) => (
						<View style={styles.tableRow} key={index}> 
							<View style={styles.tableCol}> 
								<Text style={styles.tableCell}>{moment(dose.createdAt).format('MM/DD/YY hh:mm')}</Text> 
							</View> 
							<View style={styles.tableCol}> 
								<Text style={styles.tableCell}>{dose.bottle.name}{dose.bottle.strength}</Text> 
							</View> 
							<View style={styles.tableCol}>
								<Text style={styles.tableCell}>{dose.volume}</Text> 
							</View>
							<View style={styles.tableCol}> 
								<Text style={styles.tableCell}>{dose.note}</Text> 
							</View> 
						</View> 
					))}
				</View>
			</Page>
		</Document>
	);
}
