import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
// import * as Yup from 'yup';
import { API } from 'aws-amplify';
import {LoaderButton} from '../../components';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { onError } from '../../libs/errorLib';
import { inventoryState } from '../../recoil/atoms';
import { Formik, Form } from 'formik';
import * as yup from 'yup';


let InventorySchema = yup.object().shape({
	// firstName: yup.string().required('This field is required.'),
	// lastName: yup.string().required('This field is required.'),
	// dob: yup.string().required('This field is required.'),
	// mrn: yup.string().required('This field is required.'),
});

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}));

const types = ['tree', 'grass', 'weed', 'dust mite', 'dog', 'cat', 'mold'];
const manufacturers = ['ALK', 'Greer', 'Hollister Steir'];

export default function NewInventory() {
	const classes = useStyles();
	const history = useHistory();
	const [newInventory, setNewInventory] = useState({});
	const [inventory, setInventory] = useRecoilState(inventoryState);
	const [isLoading, setIsLoading] = useState(false);

	async function submitNewInventory(values) {
		setIsLoading(true);
		let inventoryValues = values;
		setNewInventory(inventoryValues);
		try {
			// api
			await createInventory(inventoryValues);
			// local
			let newArray = [...inventory];
			newArray.push(newInventory);
			setInventory(newArray);
			history.push('/inventory');
		} catch (e) {
			onError(e);
			setIsLoading(false);
		}
	}

	function createInventory(inventory) {
		// api
		return API.post('acc', 'acc/create-inventory', {
			body: inventory
		});
		// local
	}
  
	function goBack(){
		history.goBack();
	}
	
	return (
		<Container>
			<CssBaseline />
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<Typography variant="h5"> New Inventory</Typography>
				</Grid>
			</Grid>
			<Formik
				initialValues={{
					allergenName: '',
					allergenType: '',
					stockConcentration: '',
					lot: '',
					stockExpiration: '2020-12-31',
					manufacturer: '',
					stockUnits: '',
					itemId: '',
					defaultVolume: '',
				}}
				validationSchema={InventorySchema}
				onSubmit={values => {
					submitNewInventory(values);
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
				}) => (
					<Form className={classes.form}>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									id="allergenName"
									name="allergenName"
									label="Allergen"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.allergenName}
									variant="outlined"
									fullWidth
									autoFocus
									error={errors.allergenName && touched.allergenName}
									helperText={
										errors.allergenName && touched.allergenName
											? errors.allergenName
											: null
									}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									id="allergenType"
									name="allergenType"
									label="Allergen Type"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.allergenType}
									variant="outlined"
									select
									fullWidth
									error={errors.allergenType && touched.allergenType}
									helperText={
										errors.allergenType && touched.allergenType
											? errors.allergenType
											: null
									}
								>
									{types.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={3}>
								<TextField
									id="manufacturer"
									name="manufacturer"
									label="Manufacturer"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.manufacturer}
									select
									variant="outlined"
									fullWidth
									error={errors.manufacturer && touched.manufacturer}
									helperText={
										errors.manufacturer && touched.manufacturer
											? errors.manufacturer
											: null
									}
								>
									{manufacturers.map((option) => (
										<MenuItem key={option} value={option}>
											{option}
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									id="lot"
									name="lot"
									label="Lot"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.lot}
									variant="outlined"
									fullWidth
									error={errors.lot && touched.lot}
									helperText={
										errors.lot && touched.lot
											? errors.lot
											: null
									}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									id="stockExpiration"
									name="stockExpiration"
									label="Expiration"
									type="date"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.stockExpiration}
									variant="outlined"
									fullWidth
									error={errors.stockExpiration && touched.stockExpiration}
									helperText={
										errors.stockExpiration && touched.stockExpiration
											? errors.stockExpiration
											: null
									}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									id="stockConcentration"
									name="stockConcentration"
									label="Concentration"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									value={parseFloat(values.stockConcentration)}
									variant="outlined"
									fullWidth
									error={errors.stockConcentration && touched.stockConcentration}
									helperText={
										errors.stockConcentration && touched.stockConcentration
											? errors.stockConcentration
											: null
									}
								/>
							</Grid>
							<Grid item xs={12} sm={2}>
								<TextField
									id="stockUnits"
									name="stockUnits"
									label="Units"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.stockUnits}
									variant="outlined"
									fullWidth
									error={errors.stockUnits && touched.stockUnits}
									helperText={
										errors.stockUnits && touched.stockUnits
											? errors.stockUnits
											: null
									}
								/>
							</Grid>
							<Grid item xs={12} sm={1}>
								<TextField
									id="defaultVolume"
									name="defaultVolume"
									label="Default volume"
									type="number"
									onChange={handleChange}
									onBlur={handleBlur}
									value={parseFloat(values.defaultVolume)}
									variant="outlined"
									fullWidth
									error={errors.defaultVolume && touched.defaultVolume}
									helperText={
										errors.defaultVolume && touched.defaultVolume
											? errors.defaultVolume
											: null
									}
								/>
							</Grid>
						</Grid>
						<LoaderButton
							fullWidth
							type="submit"
							isLoading={isLoading}
							className={classes.submit}
						>Create</LoaderButton>
						<LoaderButton
							fullWidth
							onClick={goBack}
						>Back
						</LoaderButton>
					</Form>
				)}
			</Formik>
		</Container>
	);
}