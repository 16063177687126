import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from 'aws-amplify';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {LoaderButton} from '../components';
import { onError } from '../libs/errorLib';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

let SignupSchema = yup.object().shape({
	firstName: yup.string().required('This field is required.'),
	lastName: yup.string().required('This field is required.'),
	dob: yup.string().required('This field is required.'),
	mrn: yup.string().required('This field is required.'),
});

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}));

export default function NewPatient() {
	const classes = useStyles();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);

	async function submitNewPatient(values) {
		setIsLoading(true);
		const { firstName, lastName, dob, mrn} = values;
		const patient = {  firstName, lastName, dob, mrn,
			patientId: `patient_${mrn}`
		};
		try {
			await createPatient(patient);
			history.push('/');
		} catch (e) {
			onError(e);
			setIsLoading(false);
		}
	}

	function createPatient(patient) {
		return API.post('patients', '/patients/create-patient', {body: patient });
	}

	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Typography component="h1" variant="h5">
          Sign up
				</Typography>
				<Formik
					initialValues={{
						firstName: '',
						lastName: '',
						dob: '',
						mrn: ''
					}}
					validationSchema={SignupSchema}
					onSubmit={values => {
						submitNewPatient(values);
					}}
				>
					{({ errors, handleChange, touched }) => (
						<Form className={classes.form}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										error={errors.firstName && touched.firstName}
										// autoComplete="fname"
										name="firstName"
										variant="outlined"
										fullWidth
										onChange={handleChange}
										id="firstName"
										label="First Name"
										autoFocus
										helperText={
											errors.firstName && touched.firstName
												? errors.firstName
												: null
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										error={errors.lastName && touched.lastName}
										variant="outlined"
										fullWidth
										onChange={handleChange}
										id="lastName"
										label="Last Name"
										name="lastName"
										// autoComplete="lname"
										helperText={
											errors.lastName && touched.lastName
												? errors.lastName
												: null
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="dob"
										name="dob"
										label="Date of Birth"
										type="date"
										fullWidth
										onChange={handleChange}
										// defaultValue="2017-05-24"
										InputLabelProps={{
											shrink: true,
										}}
										error={errors.dob && touched.dob}
										helperText={
											errors.dob && touched.dob ? errors.dob : null
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										error={errors.mrn && touched.mrn}
										variant="outlined"
										fullWidth
										onChange={handleChange}
										id="mrn"
										label="Medical Record Number"
										name="mrn"
										helperText={
											errors.mrn && touched.mrn ? errors.mrn : null
										}
									/>
								</Grid>
							</Grid>
							<LoaderButton
								fullWidth
								type="submit"
								isLoading={isLoading}
								className={classes.submit}
							>Create</LoaderButton>
						</Form>
					)}
				</Formik>
			</div>
		</Container>
	);
}


// // Add field MRN
// import React, { useRef, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { FormGroup, FormControl } from 'react-bootstrap';
// import { API } from 'aws-amplify';
// import { Formik } from 'formik';
// import * as Yup from 'yup';
// import {LoaderButton} from '../components';
// import { onError } from '../libs/errorLib';

// import './NewNote.css';

// export default function NewPatient() {
// 	const history = useHistory();
// 	const [isLoading, setIsLoading] = useState(false);

// 	// async function submitNewPatient(event) {
// 	async function submitNewPatient(values) {
// 		// event.preventDefault();
// 		setIsLoading(true);
// 		const {patientId, firstName, lastName, dob} = values;
// 		const patient = { patientId, firstName, lastName, dob };
// 		try {
// 			await createNote(patient);
// 			history.push('/');
// 		} catch (e) {
// 			onError(e);
// 			setIsLoading(false);
// 		}
// 	}
  
  
// 	function createNote(patient) {
// 		return API.post('patients', '/patients/create-patient', {body: patient });
// 	}

// 	// Schema for yup
// 	const validationSchema = Yup.object().shape({
// 		firstName: Yup.string()
// 			.min(2, '*Names must have at least 2 characters')
// 			.max(100, '*Names can\'t be longer than 100 characters')
// 			.required('*Name is required'),
// 		lastName: Yup.string()
// 			.min(2, '*Names must have at least 2 characters')
// 			.max(100, '*Names can\'t be longer than 100 characters')
// 			.required('*Name is required'),
// 	});

// 	return (
// 		<div className="NewNote">
// 			<Formik
// 				initialValues={{ email: '', password: '' }}
// 				validationSchema={validationSchema}
// 				onSubmit={(values) => {
// 					submitNewPatient(values);
// 				}}
// 			>
// 				{({
// 					values,
// 					errors,
// 					touched,
// 					handleChange,
// 					handleBlur,
// 					handleSubmit,
// 					isSubmitting,
// 					/* and other goodies */
// 				}) => (
// 					<form onSubmit={handleSubmit}>
// 						<FormGroup controlId="patientId">
// 							<FormControl
// 								type="text"
// 								name="patientId"
// 								placeholder="Medical record number"
// 								onChange={handleChange}
// 								onBlur={handleBlur}
// 								value={values.patientId}
// 							/>
// 						</FormGroup>
// 						{errors.patientId && touched.patientId && errors.patientId}
// 						<FormGroup controlId="firstName">
// 							<FormControl
// 								type="text"
// 								name="firstName"
// 								placeholder="First Name"
// 								onChange={handleChange}
// 								onBlur={handleBlur}
// 								value={values.firstName}
// 							/>
// 						</FormGroup>
// 						{errors.firstName && touched.firstName && errors.firstName}
// 						<FormGroup controlId="lastName">
// 							<FormControl
// 								type="text"
// 								name="lastName"
// 								placeholder="Last Name"
// 								onChange={handleChange}
// 								onBlur={handleBlur}
// 								value={values.lastName}
// 							/>
// 						</FormGroup>
// 						{errors.lastName && touched.lastName && errors.lastName}
// 						<FormGroup controlId="dob">
// 							<FormControl
// 								type="text"
// 								name="dob"
// 								placeholder="Dob"
// 								onChange={handleChange}
// 								onBlur={handleBlur}
// 								value={values.dob}
// 							/>
// 						</FormGroup>
// 						{errors.dob && touched.dob && errors.dob}
// 						<LoaderButton
// 							block
// 							type="submit"
// 							bsSize="large"
// 							bsStyle="primary"
// 							isLoading={isLoading}
// 							disabled={isSubmitting}
// 						>
// 						Create
// 						</LoaderButton>
// 					</form>
// 				)}
// 			</Formik>
// 		</div>
// 	);
// }