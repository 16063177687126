import React, { useState, useEffect, Suspense } from 'react';
import { useRecoilState } from 'recoil';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import {LoaderButton, PatientBanner } from '../../components';
import { API } from 'aws-amplify';
import { activePatientDemographicsState, 
	activePatientBottlesState,
	activePatientNextScitState,
	activePatientScitState,
} from '../../recoil/atoms';
import { filteredPatientListState } from '../../recoil/selectors';
import './Home.css';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	textField: {
		padding: 10,
	},
	button: {
		padding: 10,
	}
}));

export default function Scit() {
	const history = useHistory();
	const classes = useStyles();
	const [activePatientDemographics, setActivePatientDemographics] = useRecoilState(activePatientDemographicsState);
	const [activePatientScit, setActivePatientScit] = useRecoilState(activePatientScitState);

	const [activeScit, setActiveScit] = useState(null);

	const { itemId } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	useEffect(() => {
		// If local state is empty, load from localstorage
		if (Object.keys(activePatientDemographics).length === 0) {
			setActivePatientDemographics(JSON.parse(localStorage.getItem('activePatientDemographicsState')).value);
			setActivePatientScit(JSON.parse(localStorage.getItem('activePatientScitState')).value);
			setActiveScit(JSON.parse(localStorage.getItem('activePatientScitState')).value.filter((scit) => scit.itemId === itemId)[0]);
		} else {
			setActiveScit(activePatientScit.filter((scit) => scit.itemId === itemId)[0]);
		}
	}, []);

	function handleVolumeChange(volume) {
		let newObject = {...activeScit};
		newObject.volume = volume;
		setActiveScit(newObject);
	}

	function handleNoteChange(note) {
		let newObject = {...activeScit};
		newObject.note = note;
		setActiveScit(newObject);
	}

	// works only after refresh
	// Cannot assign to read only property 'note' of object '#<Object>'
	const handeleBottleChange = (bottle) => {
		console.log('new bottle', bottle, activeScit);
		let newObject = {...activeScit};
		newObject.bottle = bottle;
		setActiveScit(newObject);
	};

	function goBack(){
		history.goBack();
	}

	function updateScit(values) {
		console.log('UP', values);
		// api
		let data = {
			itemId: activeScit.itemId,
			volume: values.volume,
			note: values.note
		};
		API.put('acc', `acc/update-scit/${activeScit.itemId}`, {
			body: data
		});
		// local
		const elementsIndex = activePatientScit.findIndex(element => element.itemId === activeScit.itemId );
		let newArray = [...activePatientScit];
		newArray[elementsIndex] = {...newArray[elementsIndex],
			volume: values.volume,
			note: values.note
		};
		setActivePatientScit(newArray);
		goBack();
	}


	function deleteScit(dose) {
		// api
		API.del('patients', `/patients/delete/${activeScit.patientId}/${activeScit.itemId}`);
		// local
		const elementsIndex = activePatientScit.findIndex(element => element.itemId === dose.itemId );
		let newArray = [...activePatientScit];
		newArray.splice(elementsIndex, 1);
		setActivePatientScit(newArray);
	}

	const customStyles = {
		option: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: data.color,
				opacity: 0.8,
				color: 'white',
			};
		},
		singleValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: data.color,
				color: 'white',
			};
		},
	};

	const validationSchema = Yup.object().shape({
		// firstName: Yup.string()
		//   .min(2, 'Too Short!')
		//   .max(50, 'Too Long!')
		//   .required('Required'),
		// lastName: Yup.string()
		//   .min(2, 'Too Short!')
		//   .max(50, 'Too Long!')
		//   .required('Required'),
		// email: Yup.string()
		//   .email('Invalid email')
		//   .required('Required'),
	});
  
	return (
		<Container>
			<Suspense className={classes.paper} fallback={<div>Loading...</div>}>
				<PatientBanner patient={activePatientDemographics} />
				<Typography component="h1" variant="h5">
					Edit Scit
				</Typography>
				{activeScit && (
					<Formik
						initialValues={{ volume: activeScit.volume, note: activeScit.note }}
						onSubmit={(values) => {
							updateScit(values);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<Grid container spacing={2}>

					
								<Form className={classes.form}>
									<Typography component="h1" variant="h5">
								Bottle: {activeScit.bottle.name}{activeScit.bottle.strength}
									</Typography>
									<Grid item xs={12}>
										<TextField
											className={classes.textField}
											error={errors.volume && touched.volume}
											name="volume"
											variant="outlined"
											fullWidth
											value={values.volume}
											onChange={handleChange}
											onBlur={handleBlur}
											id="volume"
											label="Volume"
											autoFocus
											helperText={
												errors.volume && touched.volume
													? errors.volume
													: null
											}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											className={classes.textField}
											error={errors.note && touched.note}
											name="note"
											variant="outlined"
											fullWidth
											value={values.note}
											onChange={handleChange}
											onBlur={handleBlur}
											id="note"
											label="note"
											autoFocus
											helperText={
												errors.note && touched.note
													? errors.note
													: null
											}
										/>
									</Grid>
									<LoaderButton
										type="submit"
										isLoading={isLoading}
										disabled={isSubmitting}
									>Update</LoaderButton>
								</Form>
								<LoaderButton
									type="submit"
									isLoading={isLoading}
									disabled={isSubmitting}
								>Delete</LoaderButton>
							</Grid>
						)}
					</Formik>
				)}
			</Suspense>
		</Container>
	);
}
