import React from 'react';
import { Table } from 'react-bootstrap';
// import moment from 'moment';

export function Queue({
	queueList,
	rowSelected,
	...props
}) {

	function renderRow(array) {
		return (
			<>
				{array.map((patient, index) => {
					return (
						<tr key={patient.patientId} onClick={() => rowSelected(patient)}>
							<td>{patient.firstName}</td>
							<td>{patient.lastName}</td>
						</tr>
					);
				})}
			</>
		);
	}

	return (
		<div>
			<Table striped bordered hover size="sm">
				<thead>
					<tr>
						<th>First Name</th>
						<th>Last Name</th>
					</tr>
				</thead>
				<tbody>
					{renderRow(queueList)}
				</tbody>
			</Table>
		</div>
	);
}
