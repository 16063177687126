import { atom } from 'recoil';

export const patientListState = atom({
	key: 'patientListState',
	default: [],
	persistence_UNSTABLE: { type: true },
});

export const locationState = atom({
	key: 'locationState',
	default: 'norwalk',
});

export const activePatientDemographicsState = atom({
	key: 'activePatientDemographicsState',
	default: {},
	persistence_UNSTABLE: { type: true },
});

export const activePatientBottlesState = atom({
	key: 'activePatientBottlesState',
	default: [],
	persistence_UNSTABLE: { type: true },
});

export const activePatientScitState = atom({
	key: 'activePatientScitState',
	default: [],
	persistence_UNSTABLE: { type: true },
});

export const activePatientNextScitState = atom({
	key: 'activePatientNextScitState',
	default: [],
	persistence_UNSTABLE: { type: true },
});

export const inventoryState = atom({
	key: 'inventoryState',
	default: [],
	persistence_UNSTABLE: { type: true },
});

export const chargesState = atom({
	key: 'chargesState',
	default: [],
	persistence_UNSTABLE: { type: true },
});