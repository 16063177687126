const dev = {
	s3: {
		REGION: 'us-east-1',
		BUCKET: 'dev-allergy-infrastructure-s3-uploads4f6eb0fd-1vd3vsf1pkyzm'
	},
	apiGateway: {
		REGION: 'us-east-1',
		ACC_URL: 'https://19luyuqrjd.execute-api.us-east-1.amazonaws.com/dev/'
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_3yXddqcDU',
		APP_CLIENT_ID: '5t6p902jcdmeter5jf647e3jbj',
		IDENTITY_POOL_ID: 'us-east-1:0c2446d8-0973-40f4-ad64-adf8f5266361'
	}
};
  
const prod = {
	s3: {
		REGION: 'us-east-1',
		BUCKET: 'prod-allergy-infrastructure-s3-uploads4f6eb0fd-1q3b8onzgmdms'
	},
	apiGateway: {
		REGION: 'us-east-1',
		ACC_URL: 'https://2q4rgcwhpc.execute-api.us-east-1.amazonaws.com/prod/'
	},
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_egDw3JeGD',
		APP_CLIENT_ID: '5v29ldg7tupadasgf32lvcjkjf',
		IDENTITY_POOL_ID: 'us-east-1:145a2048-e7d6-47d6-a959-e27cad6cd980'
	}
};
  
// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
	? prod
	: dev;
  
export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...config
};
  
// const dev = {
// 	STRIPE_KEY: 'pk_test_UxXUfQxsOFCubQNXk2VpL94O00dVtIl04y',
// 	s3: {
// 		REGION: 'us-east-1',
// 		BUCKET: 'allergy-app-api-ext-uploads-dev-s3bucket-1q17xslvy5w7g'
// 	},
// 	apiGateway: {
// 		REGION: 'us-east-1',
// 		DOSE_URL: 'https://j093ahptqi.execute-api.us-east-1.amazonaws.com/dev',
// 		USER_URL: 'https://htzlc1s259.execute-api.us-east-1.amazonaws.com/dev',
// 		PATIENTS_URL: 'https://oup58sl1b0.execute-api.us-east-1.amazonaws.com/dev',
// 		ACC_URL: 'https://19luyuqrjd.execute-api.us-east-1.amazonaws.com/dev/'
// 	},
// 	cognito: {
// 		REGION: 'us-east-1',
// 		USER_POOL_ID: 'us-east-1_K72umnNCL',
// 		APP_CLIENT_ID: '5gq2rnon68gebkgt9p3caru0d8',
// 		IDENTITY_POOL_ID: 'us-east-1:4ad032d9-525a-4182-b1a6-fe158a9c9bef'
// 	},
// 	social: {
// 		FB: '255258875787278'
// 	}
// };

// const prod = {
// 	STRIPE_KEY: 'pk_test_UxXUfQxsOFCubQNXk2VpL94O00dVtIl04y',
// 	s3: {
// 		REGION: 'us-east-1',
// 		BUCKET: 'allergy-app-api-ext-uploads-prod-s3bucket-4j9g0bbm06ig'
// 	},
// 	apiGateway: {
// 		REGION: 'us-east-1',
// 		DOSE_URL: 'https://rqw685slsi.execute-api.us-east-1.amazonaws.com/prod',
// 		USER_URL: 'https://2b725phmf9.execute-api.us-east-1.amazonaws.com/prod',
// 		ACC_URL: 'https://19luyuqrjd.execute-api.us-east-1.amazonaws.com/prod/'
// 	},
// 	cognito: {
// 		REGION: 'us-east-1',
// 		USER_POOL_ID: 'us-east-1_xynFGy4M2',
// 		APP_CLIENT_ID: '5ske4b5p920sske2emsg61b6tc',
// 		IDENTITY_POOL_ID: 'us-east-1:f28b8329-73d6-49f4-bf45-1c107f2fe433'
// 	},
// 	social: {
// 		FB: '255258875787278'
// 	}
// };

// // Default to dev if not set
// const config = process.env.REACT_APP_STAGE === 'prod'
// 	? prod
// 	: dev;

// export default {
// 	// Add common config values here
// 	MAX_ATTACHMENT_SIZE: 5000000,
// 	...config
// };