import React, { useEffect, useState, Suspense } from 'react';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik, FieldArray } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import { Divider, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { API } from 'aws-amplify';
import {mapValues} from 'lodash';
import * as Yup from 'yup';
import { LoaderButton, PatientBanner, ScitTable } from '../../components';
import { onError } from '../../libs/errorLib';
import { useAppContext } from '../../libs/contextLib';
import { activePatientDemographicsState, 
	activePatientBottlesState,
	activePatientNextScitState,
	activePatientScitState,
} from '../../recoil/atoms';
import './NewScit.css';

const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white
		}
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	textField: {
		padding: 10,
	},
	button: {
		padding: 10,
		margin: 5,
	},
	divider: {
		margin: theme.spacing(2, 0),
	},
	menu: {
		backgroundColor: 'red',
	},
}));

export default function NewScit() {
	const classes = useStyles();
	const history = useHistory();
	const { isAuthenticated } = useAppContext();
	const [isLoading, setIsLoading] = useState(false);

	// Load from recoilState
	const [activePatientDemographics, setActivePatientDemographics] = useRecoilState(activePatientDemographicsState);
	const [activePatientBottles, setActivePatientBottles] = useRecoilState(activePatientBottlesState);
	const [activePatientScit, setActivePatientScit] = useRecoilState(activePatientScitState);
	const [activePatientNextScit, setActivePatientNextScit] = useRecoilState(activePatientNextScitState);

	useEffect(() => {
		async function onLoad() {
			setIsLoading(true);
			if (!isAuthenticated) {
				return;
			}
			// If local state is empty, load from localstorage
			if (Object.keys(activePatientDemographics).length === 0) {
				setActivePatientDemographics(JSON.parse(localStorage.getItem('activePatientDemographicsState')).value);
				setActivePatientBottles(JSON.parse(localStorage.getItem('activePatientBottlesState')).value);
				setActivePatientScit(JSON.parse(localStorage.getItem('activePatientScitState')).value);
				setActivePatientNextScit(JSON.parse(localStorage.getItem('activePatientNextScitState')).value);
			}
			setIsLoading(false);
		}
		onLoad();
	}, [isAuthenticated]);

	async function submitNewScit(values) {
		setIsLoading(true);
		try {
			await createNewScit(values);
			// add to state
			let newArray = [...activePatientScit];
			values.shots.map((scit) => {
				newArray.unshift(scit);
			});
			setActivePatientScit(newArray);
			history.push('/');
		} catch (e) {
			onError(e);
			setIsLoading(false);
		}
	}

	function createNewScit(values) {
		let data = {
			patient: activePatientDemographics,
			scitDoses: values.shots,			
		};
		return API.post('acc', 'acc/create-scit', {body: data });
	}

	function goBack(){
		history.goBack();
	}

	// Schema for yup
	const validationSchema= Yup.lazy(obj =>
		Yup.object(
			mapValues(obj, (value, key) => {
				if (key.includes('bottle')) {
					return Yup.string().required();
				}
				if (key.includes('volume')) {
					return Yup.number().typeError('Volume must be a number')
						.positive('Volume must be greater than zero')
						.required('Volume is required');
				}
			})
		)
	);

	return (
		<Container>
			<Suspense fallback={<div>Loading...</div>}>
				<PatientBanner patient={activePatientDemographics} />
				<Typography component="h1" variant="h5">
					New Shot
				</Typography>

				<Formik
					enableReinitialize={true}
					initialValues={{shots: activePatientNextScit}}
					// validationSchema={validationSchema}
					onSubmit={(values) => {
						submitNewScit(values);
					}}
				>
					{({
						errors,
						touched,
						values,
						setFieldTouched,
						setFieldValue,
						isValid,
						/* and other goodies */
					}) => (
						<Form className={classes.form}>
							<FieldArray
								name="shots"
								render={() => (
									<div>
										{values.shots && values.shots.length > 0 ? (
											values.shots.map((dose, index) => (
												<div key={index}>
													<Grid container>
														<Grid item xs={4}>
															<TextField
																className={classes.textField}
																id={`shots[${index}].bottle`}
																name={`shots[${index}].bottle`}
																value={dose.bottle.itemId}
																style={{ backgroundColor: values.shots[index].bottle.color }}
																select
																fullWidth
																variant="outlined"
																onChange={e => {
																	setFieldValue(`shots[${index}].bottle`, activePatientBottles.filter(item => item.itemId === e.target.value)[0]);
																	setFieldTouched('bottle', true);
																}}
																onBlur={e => {
																	setFieldValue(`shots[${index}].bottle`, activePatientBottles.filter(item => item.itemId === e.target.value)[0]);
																	setFieldTouched('bottle', true);
																}}
																helperText="Please select your bottle"
															>
																{activePatientBottles.filter(item => item.name.startsWith(dose.bottle.name)).map((option) => (
																	<MenuItem
																		key={option.itemId}
																		value={option.itemId}
																		style={{ backgroundColor: option.color }}
																	>
																		{option.name}{option.strength}
																	</MenuItem>
																))}
															</TextField>
														</Grid>
														<Grid item xs={2}>
															<TextField
																className={classes.textField}
																error={errors.volume && touched.volume}
																id={`shots[${index}].volume`}
																name={`shots[${index}].volume`}
																value={dose.volume}
																variant="outlined"
																type="text"
																onChange={e => {
																	setFieldValue(`shots[${index}].volume`, e.target.value);
																	setFieldTouched('volume', true);
																}}
																onBlur={e =>{
																	setFieldValue(`shots[${index}].volume`, e.target.value);
																	setFieldTouched('volume', true);
																}}
																label="Volume"
																helperText={
																	errors.volume && touched.volume
																		? errors.volume
																		: null
																}
															/>
														</Grid>
													</Grid>
												</div>
											))
										) : (
											<TextField label="loading ..." />
										)}
									</div>
								)}
							/>
							<LoaderButton
								className={classes.button}
								fullWidth
								type="submit"
								isLoading={isLoading}
								disabled={!isValid}
							>Create</LoaderButton>
							<LoaderButton
								className={classes.button}
								fullWidth
								type="button"
								onClick={goBack}
							>Back</LoaderButton>
						</Form>		
					)}
				</Formik>
				<Divider className={classes.divider} />
				<div>Next shot in {<input disabled={true}/>} weeks.</div>
				<Divider className={classes.divider} />
				<div>
					<ScitTable scitData={activePatientScit} isLoading={isLoading} />
				</div>
			</Suspense>
		</Container>
		
	);
}
