import React from 'react';
import { RecoilRoot, useTransactionObservation_UNSTABLE } from 'recoil';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import config from './config';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initSentry } from './libs/errorLib';
// import { activePatientDemographicsState } from './recoil/atoms';

initSentry();

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID
	},
	API: {
		endpoints: [
			{
				name: 'users',
				endpoint: config.apiGateway.USER_URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'patients',
				endpoint: config.apiGateway.PATIENTS_URL,
				region: config.apiGateway.REGION
			},
			{
				name: 'acc',
				endpoint: config.apiGateway.ACC_URL,
				region: config.apiGateway.REGION
			},
		]
	}
});

function PersistenceObserver() {
	useTransactionObservation_UNSTABLE(({atomValues, atomInfo, modifiedAtoms}) => {
		for (const modifiedAtom of modifiedAtoms) {
			window.localStorage.setItem(
				modifiedAtom,
				JSON.stringify({value: atomValues.get(modifiedAtom)}),
			);
		}
	});
	return null;
}

// const recoilKeys = [
// 	'activePatientDemographicsState',
// 	'activePatientBottlesState',
// 	'activePatientScitState',
// 	'activePatientNextScitState'
// ];

// const initializeState = ({set}) => {
// 	recoilKeys.foreach(key => {
// 		let value = localStorage.getItem(key);
// 		console.log('kv', key, value);
// 		set(getAtomWithKey(key), JSON.parse(value)).value;
// 	});
// };

const theme = createMuiTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				html: {
					WebkitFontSmoothing: 'auto',
				},
				body: {
					fontSize: 16,
				}
			},
		},
		MuiTableCell: {
			root: {
				fontSize: 16,
			},
		}
	},
});

ReactDOM.render(
	<RecoilRoot>
		<PersistenceObserver />
		<Router>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<App />
			</ThemeProvider>
		</Router>
	</RecoilRoot>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
