import * as Sentry from '@sentry/browser';

const isLocal = process.env.NODE_ENV === 'development';

export function initSentry() {
	if (isLocal) {
		return;
	}

	Sentry.init({dsn: 'https://8b3c0d4fe6d14833b40fe7b9d6c7e7a0@o388124.ingest.sentry.io/5224500'});
}

export function logError(error, errorInfo = null) {
	if (isLocal) {
		return;
	}

	Sentry.withScope((scope) => {
		errorInfo && scope.setExtras(errorInfo);
		Sentry.captureException(error);
	});
}

export function onError(error) {
	let errorInfo = {};
	let message = error.toString();

	// Auth errors
	if (!(error instanceof Error) && error.message) {
		errorInfo = error;
		message = error.message;
		error = new Error(message);
		// API errors
	} else if (error.config && error.config.url) {
		errorInfo.url = error.config.url;
	}

	logError(error, errorInfo);

	alert(message);
}