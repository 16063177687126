import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {AuthenticatedRoute, UnauthenticatedRoute} from './components';
import Home from './containers/Patient/Home';
import Dashboard from './containers/Dashboard';
import Login from './containers/Auth/Login';
import ChangeEmail from './containers/Auth/ChangeEmail';
import ResetPassword from './containers/Auth/ResetPassword';
import Signup from './containers/Auth/Signup';
import NewNote from './containers/NewNote';
import NewPatient from './containers/NewPatient';
import Bottle from './containers/Patient/Bottle';
import Bottles from './containers/Patient/Bottles';
import NewBottles from './containers/Patient/NewBottles';
import NewScit from './containers/Patient/NewScit';
import ScitHistory from './containers/Patient/ScitHistory';
import Scit from './containers/Patient/Scit';
import NewInventory from './containers/Inventory/NewInventory';
import Inventory from './containers/Inventory/Inventory';
import Dose from './containers/Dose';
import Settings from './containers/Settings';
import ChangePassword from './containers/Auth/ChangePassword';
import NotFound from './containers/NotFound';
import Charges from './containers/Charges';


export default function Routes() {
	return (
		<Switch>
			<Route exact path="/">
				<Dashboard />
			</Route>
			<UnauthenticatedRoute exact path="/login">
				<Login />
			</UnauthenticatedRoute>
			<UnauthenticatedRoute exact path="/login/reset">
				<ResetPassword />
			</UnauthenticatedRoute>
			<UnauthenticatedRoute exact path="/signup">
				<Signup />
			</UnauthenticatedRoute>
			<AuthenticatedRoute exact path="/home/:mrn">
				<Home />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/home/:patientId/bottle/:itemId">
				<Bottle />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/home/:patientId/bottles">
				<Bottles />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/home/:patientId/bottles/new">
				<NewBottles />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/home/:patientId/scit/new">
				<NewScit />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/home/:patientId/scit/history">
				<ScitHistory />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/home/:mrn/:itemId/edit">
				<Scit />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/settings">
				<Settings />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/settings/email">
				<ChangeEmail />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/settings/password">
				<ChangePassword />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/notes/new">
				<NewNote />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/inventory">
				<Inventory />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/inventory/new">
				<NewInventory />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/doses/patient-dose/:doseId">
				<Dose />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/patient/new">
				<NewPatient />
			</AuthenticatedRoute>
			<AuthenticatedRoute exact path="/charges">
				<Charges />
			</AuthenticatedRoute>
			{/* Finally, catch all unmatched routes */}
			<Route>
				<NotFound />
			</Route>
		</Switch>
	);
}