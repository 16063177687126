import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Routes from './Routes';
import ErrorBoundary from './components/ErrorBoundary';
import Header from './components/Header';
import { AppContext } from './libs/contextLib';
import { onError } from './libs/errorLib';
// import config from './config';
import './App.css';

function App() {
	const history = useHistory();
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [isAuthenticated, userHasAuthenticated] = useState(false);
    
	useEffect(() => {
		onLoad();
	}, []);
  
	async function onLoad() {
		// loadFacebookSDK();
		// Check for current user
		try {
			await Auth.currentSession();
			userHasAuthenticated(true);
		}
		catch(e) {
			if (e !== 'No current user') {
				onError(e);
			}
		}
		// Facebook
		// function loadFacebookSDK() {
		// 	window.fbAsyncInit = function() {
		// 		window.FB.init({
		// 			appId            : config.social.FB,
		// 			autoLogAppEvents : true,
		// 			xfbml            : true,
		// 			version          : 'v3.1'
		// 		});
		// 	};
    
		// 	(function(d, s, id){
		// 		var js, fjs = d.getElementsByTagName(s)[0];
		// 		if (d.getElementById(id)) {return;}
		// 		js = d.createElement(s); js.id = id;
		// 		js.src = 'https://connect.facebook.net/en_US/sdk.js';
		// 		fjs.parentNode.insertBefore(js, fjs);
		// 	}(document, 'script', 'facebook-jssdk'));
		// }

		setIsAuthenticating(false);
	}
  
	async function handleLogout() {
		await Auth.signOut();
		userHasAuthenticated(false);
		localStorage.clear();
		history.push('/login');
	}

	return (
		!isAuthenticating && (
			<div className="App container">
				<Header isAuthenticated={isAuthenticated} handleLogout={handleLogout}/>
				<ErrorBoundary>
					<AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
						<Routes />
					</AppContext.Provider>
				</ErrorBoundary>
			</div>
		)
	);
}

export default App;