import { selector } from 'recoil';
import { locationState, patientListState } from './atoms';

export const filteredPatientListState = selector({
	key: 'filteredPatientListState',
	get: async ({get}) => {
		const filter = get(locationState);
		const list = await get(patientListState);
		switch (filter) {
		case 'norwalk':
			return list.filter((item) => item.queue === 'norwalk');
		case 'shelton':
			return list.filter((item) => item.queue === 'shelton');
		default:
			return list.filter((item) => item.queue === 'none');
		}
	},
});