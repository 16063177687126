import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	toolbar: {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
	toolbarTitle: {
		flex: 1,
	},
	toolbarSecondary: {
		justifyContent: 'space-between',
		overflowX: 'auto',
	},
	toolbarLink: {
		padding: theme.spacing(1),
		flexShrink: 0,
	},
	sectionDesktop: {
        display: 'none',
        alignItems: 'right',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
}));

export default function Header(props) {
	const classes = useStyles();
	const { handleLogout, isAuthenticated } = props;

	return (
		<div className={classes.grow}>
			<AppBar position="static">
				<Toolbar className={classes.toolbar}>
					<Link
						color="inherit"
						variant="h5"
						href="/"
						className={classes.toolbarLink}
					>Allergy</Link>
					{isAuthenticated ? (
						<div className={classes.sectionDesktop}>
							<Link
								color="inherit"
								variant="h5"
								href="/charges"
								className={classes.toolbarLink}
							>Charges</Link>
							<Link
								color="inherit"
								variant="h5"
								href="/inventory"
								className={classes.toolbarLink}
							>Inventory</Link>
							<Link
								color="inherit"
								variant="h5"
								href="/settings"
								className={classes.toolbarLink}
							>Settings</Link>
							<Link
								color="inherit"
								variant="h5"
								href="/"
								className={classes.toolbarLink}
								onClick={handleLogout}
							>Logout</Link>
						</div>
					) : (
						<div className={classes.sectionDesktop}>
							<Link
								color="inherit"
								variant="h5"
								href="/signup"
								className={classes.toolbarLink}
							>Sign up</Link>
							<Link
								color="inherit"
								variant="h5"
								href="/login"
								className={classes.toolbarLink}
							>Login</Link>
						</div>
					)}
				</Toolbar>
			</AppBar>
		</div>
	);
}

Header.propTypes = {
	handleLogout: PropTypes.func,
	isAuthenticated: PropTypes.bool,
};
